// Jonathon Martin, Elementice, March 2019
import { Config, CognitoIdentityCredentials } from 'aws-sdk/global';

import {
    CognitoUser,
    CognitoUserPool,
    AuthenticationDetails,
    CognitoUserAttribute,
} from 'amazon-cognito-identity-js';


interface LocalUserObj {
    sub: string;
    email: string;
    given_name: any;
    family_name: any;
}

import { EIUser } from '../../models/user';
import { UserService } from '../user/user.service';
import { SocketService } from '../sockets/sockets.service';
import { MessageService } from '../message/message.service';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { EnvService } from '../env/env.service';

@Injectable({
    providedIn: 'root'
})
export class CognitoService {

    constructor(
        private socketService: SocketService,
        private userService: UserService,
        private messages: MessageService,
        private logger: NGXLogger,
        private envService: EnvService) {
        this.configure();
    }

    ///////////////////////////
    // Configure aws resources
    ///////////////////////////
    config: Config = new Config();

    lastTokenRenew;


    // object to return
    currentUser: LocalUserObj;

    ////////////////////////////
    // internal helper functions
    ////////////////////////////

    private configure() {
        this.config.region = this.envService.env.awsRegion;
        this.config.credentials = new CognitoIdentityCredentials({
            IdentityPoolId: this.envService.env.awsIdentityPoolId
        });
    }

    // uses env variables to return a userPool object
    private getUserPool() {
        this.logger.info('Getting user pool...');
        const poolData = {
            UserPoolId: this.envService.env.awsUserPoolId,
            ClientId: this.envService.env.awsClientId
        };
        const userPool = new CognitoUserPool(poolData);
        return userPool;
    }

    // uses userPool and username to return a user object
    private getUser(userPool: CognitoUserPool, username: string) {
        this.logger.info('Getting cognito user...');
        const userData = {
            Username: username,
            Pool: userPool
        };
        const cognitoUser = new CognitoUser(userData);
        return cognitoUser;
    }

    // creates an object from credentials that will be used to authenticate a user
    private getAuthenticationDetails(username: string, password: string) {
        const authenticationData = {
            Username: username,
            Password: password
        };
        const authenticationDetails = new AuthenticationDetails(authenticationData);
        return authenticationDetails;
    }

    // changes weird aws attribute format into something more usable
    private convertUserAttrsToObject(userAttrs: CognitoUserAttribute[]): LocalUserObj {
        const userObject = { email: '', given_name: '', family_name: '', sub: '' };
        userAttrs.forEach((attr: CognitoUserAttribute) => {
            userObject[attr.getName()] = attr.getValue();
        });
        return userObject;
    }

    // set all params that show the user is authenticated and logged in
    private setLoggedin(userAttrs: CognitoUserAttribute[], accessToken: string, tokenExpiry) {
        this.logger.info('User logged in!');
        this.currentUser = this.convertUserAttrsToObject(userAttrs);
        const user: EIUser = {
            uid: this.currentUser.sub,
            email: this.currentUser.email,
            firstName: this.currentUser.given_name,
            lastName: this.currentUser.family_name,
            memberId: parseInt(this.currentUser['custom:memberId'], 10),
            isAdmin: this.currentUser['custom:isAdmin'] === '1',
            isManager: this.currentUser['custom:isManager'] === '1',
            tokenExpiry,
            token: accessToken
        };
        this.userService.user = user;

        // Add the User's Id Token to the Cognito credentials login map.
        const loginsObj = {};
        loginsObj['cognito-idp.' + this.envService.env.awsRegion + '.amazonaws.com/' + this.envService.env.awsUserPoolId] = accessToken;
        this.config.credentials = new CognitoIdentityCredentials({
            IdentityPoolId: this.envService.env.awsIdentityPoolId,
            Logins: loginsObj
        });

        // Set a cookie
        // document.cookie = 'token=' + this.userService.user.token + '; expires=' + tokenExpiry.toGMTString() + '; path=/';

        this.messages.broadcast('authenticated', true);

        this.lastTokenRenew = (new Date()).getTime();

        // 10 seconds after token expires, request a new one
        this.logger.info('Logged in. Token will be renewed in ' +
            parseInt(((tokenExpiry.getTime() - (new Date()).getTime()) / 1000).toString(), 10) + ' seconds.');
        const renewTime = (tokenExpiry.getTime() - (new Date()).getTime()) + 10000;
        setTimeout(() => {
            // Ensure that over an hour has passed (required cause when PCs sleep, they pause setTimeouts :|)
            if ((new Date()).getTime() - this.lastTokenRenew < 60 * 60 * 1000) { return; }

            this.lastTokenRenew = (new Date()).getTime();
            this.logger.info('TOKEN EXPIRED. Fetching a new one.');
            this.checkifUserLoggedIn().then(() => {
                this.logger.info('New token saved.');
            }).catch(err => this.logger.error(err));
        }, renewTime);
    }

    /////////////////////
    // Exported functions
    /////////////////////

    // authenticate user and set JWT token on rootScope
    // set currentUser object
    public login(username, password, callback) {
        const userPool = this.getUserPool();
        const userAuthDetails = this.getAuthenticationDetails(username, password);
        const cognitoUser = this.getUser(userPool, username);
        this.logger.info('Authenticating user...');
        // attempt to authenticate user using generated auth object
        cognitoUser.authenticateUser(userAuthDetails, this.authenticateUserCallbacks(cognitoUser, callback));

    }

    private authenticateUserCallbacks(cognitoUser, callback) {
        return {
            // if successful, get tokens and user attrs
            onSuccess: (result) => {
                // this.logger.info(result)
                const accessToken = result.getIdToken().getJwtToken();
                cognitoUser.getUserAttributes((err, userAttrs) => {
                    if (err) {
                        // this.logger.info(err)
                        callback({ success: false });
                    } else {
                        // this.logger.info(userAttrs)
                        const tokenExpiry = new Date(result.getIdToken().getExpiration() * 1000);
                        this.setLoggedin(userAttrs, accessToken, tokenExpiry);
                        callback({ success: true });
                    }
                });
            },
            // on failure, report to caller
            onFailure: (err) => {
                try {
                    this.logger.info(err);
                    callback({ success: false });
                } catch (error) {
                    this.logger.info(error);
                }
            },

            // when auth was successful, but user needs to reset password
            // provides a callback to the calling function so we can keep the session context
            // but confusing to use, see loginController.
            newPasswordRequired: (userAttributes, requiredAttributes) => {

                callback({ success: false, newPasswordRequired: true }, (newPassword) => {
                    try {
                        cognitoUser.completeNewPasswordChallenge(newPassword, {}, this.authenticateUserCallbacks(cognitoUser, callback));

                    } catch (error) {
                        this.logger.info(error);
                    }

                });
            }

        };
    }

    // call signout on current user object
    public logout() {
        try {
            const userPool = this.getUserPool();
            const cognitoUser = this.getUser(userPool, this.currentUser.email);
            cognitoUser.signOut();
        } catch (error) {
            this.logger.info(error);
        }
        this.userService.user = null;
        document.cookie = 'token=';
    }

    public clearSession() {

        this.socketService.close();
        this.userService.user = null;

    }

    // quick way to check logged in status for navigation purposes
    public isLoggedIn(): boolean {
        if (!this.currentUser) { return false; }
        const loggedIn = this.userService.user !== null;
        return loggedIn;
    }

    // attempt to retrive previous session, use those creds to log in.
    public async checkifUserLoggedIn() {
        this.logger.info('Checking user auth state...');
        const userPool: CognitoUserPool = this.getUserPool();
        const cognitoUser = userPool.getCurrentUser();
        return new Promise((resolve, reject) => {
            if (cognitoUser != null) {
                // retrieve valid session
                cognitoUser.getSession((err, res) => {
                    if (err) {
                        this.clearSession();
                        this.logger.error(err);
                        reject(err);
                    } else {
                        /// this.logger.info(res)
                        // user is now authenticated so get attributes
                        cognitoUser.getUserAttributes((error, userAttrs) => {
                            if (error) {
                                this.logger.error(error);
                                reject(error);
                            } else {
                                // this.logger.info(userAttrs)
                                const accessToken = res.getIdToken().getJwtToken();;
                                const tokenExpiry = new Date(res.getIdToken().getExpiration() * 1000);
                                this.setLoggedin(userAttrs, accessToken, tokenExpiry);
                                resolve();
                            }
                        });
                    }
                });

            } else {
                this.clearSession();
                reject();
            }
        });
    }

    // start password reset flow
    public forgotPassword(username, callback) {

        const userPool = this.getUserPool();
        const cognitoUser = this.getUser(userPool, username);

        cognitoUser.forgotPassword({

            onSuccess: (result) => {
                callback({ success: true, data: result });
            },

            inputVerificationCode: (result) => {
                callback({ success: true, data: result });

            },

            onFailure: (err) => {
                callback({ success: false, data: err });
            }


        });
    }

    // call with verification code to reset password
    public resetPasswordWithCode(username, verificationCode, newPassword, callback) {

        const userPool = this.getUserPool();
        const cognitoUser = this.getUser(userPool, username);

        cognitoUser.confirmPassword(verificationCode, newPassword, {

            onSuccess: () => {
                callback({ success: true });
            },

            onFailure: (err) => {
                callback({ success: false, data: err });
            }

        });
    }

}

