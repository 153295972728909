import { Component, AfterViewInit, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import intlTelInput from 'intl-tel-input';
import { EnvService } from 'src/app/services/env/env.service';

@Component({
  selector: 'app-phone-input',
  templateUrl: './phone-input.component.html',
  styleUrls: ['./phone-input.component.scss']
})
export class PhoneInputComponent implements AfterViewInit {

  @Input() value;
  @Output() valueChange = new EventEmitter();

  @Input() valid;
  @Output() validChange = new EventEmitter();

  @ViewChild('intlTelInput') private inputElement: ElementRef;

  private iti;

  constructor(private envService: EnvService) { }

  ngAfterViewInit(): void {
    this.iti = intlTelInput(this.inputElement.nativeElement, {
      initialCountry: 'auto',
      utilsScript: 'assets/js/utils.js',
      preferredCountries: [
        'au', 'ca', 'nz', 'ae', 'gb', 'us', 'za'
      ],
      geoIpLookup: (success, failure) => {
        fetch(`${this.envService.env.ipStackCheck}`)
        .then(res => res.json().then((resp) => {
          const countryCode = resp?.country_code || this.envService.env.DEFAULT_PHONE_COUNTRY;
          success(countryCode);
        })).catch(() => {
          failure();
        });
      },
    });
    this.inputElement.nativeElement.addEventListener('countrychange', () => {
      this.handleChange();
    });
  }

  handleChange() {
    this.valid = this.iti.isValidNumber();
    this.validChange.emit(this.valid);
    this.valueChange.emit(this.iti.getNumber());
  }

}
