<app-modal-header title="Upload Images"></app-modal-header>

<ion-content>
  <form [formGroup]="idForm" class=id-input [noValidate]=false>
  <ion-grid>
    <ion-row>
      <ion-col size=12>
        <app-typeahead
        (add)=addId($event)
        (addNew)=addNew($event)
        [values]=subjects
        [member]=memberId
        [allowNew]=true
        >
        </app-typeahead>
      </ion-col>
      <ion-col size=12>
        <div class=chips>
          <app-subject-chips [subjects]=subjects action="remove" (clicked)=removeId($event)></app-subject-chips>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</form>

<ion-grid class=float-bottom>
  <ion-row>
    <ion-col>
      <app-search-select [list]=idsService.getEventsForSelect() placeholder="Choose Event" [(value)]=eventId></app-search-select>
    </ion-col>
  </ion-row>
  <app-upload-area [(files)]=files (ready)="ready = $event" (uploading)="uploading = $event"> </app-upload-area>
  <!-- <div class="price-note">
    <ion-icon name="alert-circle-outline"></ion-icon> Please see bulk uploader pricing <a href="https://www.elementice.com/pricing" target="_blank">here</a>.
  </div> -->
</ion-grid>
</ion-content>


<app-modal-footer
(left)=close()
(right)=upload()
rightColor=primary
rightLabel=Upload
[rightDisabled]="(!(ready) || uploading || !eventId)">
</app-modal-footer>