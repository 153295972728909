import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import { filter, shareReplay, skipWhile, take, tap } from 'rxjs/operators';
import { UploadJob } from '../models/upload-job';
import { ConnectionStateService } from '../services/connection-state/connection-state.service';

@Injectable()
export abstract class JobManager {

    public uploadJobs: UploadJob[] = [];

    private onUpdatedJobs: BehaviorSubject<UploadJob[]> = new BehaviorSubject([]);

    // eslint-disable-next-line @typescript-eslint/member-ordering
    public onUpdatedJobs$ = this.onUpdatedJobs.pipe(map(jobs =>
        jobs.map(j => ({...j, jobType: this.jobType, cancelWarning: this.cancelWarning, jobIcon: this.jobIcon}))));

    abstract jobType: string;
    abstract jobIcon: string;
    abstract cancelWarning: string;


    emitOnConnected$ = this.connection.state$.pipe(shareReplay(1), filter((connected) => connected));

    constructor(private connection: ConnectionStateService) {
        this.connection.state$.subscribe((x) => {
            console.log('source', x)
        })
    }

    private async shouldContinue() {
        let x = this.emitOnConnected$.pipe(take(1)).toPromise();
        return x
    }

    async addJob(job: UploadJob) {
        job.id = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5);
        job = await this.setupJob(job);
        this.uploadJobs.push(job);
        this.onUpdatedJobs.next(this.uploadJobs);

        for (const item of job.items) {
            // console.log(item);
            if (job.cancelled) {
                const index = this.uploadJobs.indexOf(job);
                if (index > -1) {
                    this.uploadJobs.splice(index, 1);
                }
                break;
            }
            
            try {
                await this.processWithRetry(item, job);
                job.processedItems.push(item);
            } catch (err) {
                job.failedItems.push(item);
            }
        }
    }

    removeJob(job: UploadJob) {
        if (job.items.length === job.processedItems.length + job.failedItems.length) {
            const index = this.uploadJobs.findIndex(j => j.id === job.id);
            if (index > -1) {
                this.uploadJobs.splice(index, 1);
            }
        } else {
            job.cancelled = true;
        }
        this.onUpdatedJobs.next(this.uploadJobs);
    }

    abstract processItem(item, job: UploadJob): Promise<void>;

    abstract setupJob(job: UploadJob): Promise<UploadJob>;

    private async processWithRetry(item, job: UploadJob, attempt = 0): Promise<void> {
        try {
            await this.shouldContinue();
            await this.processItem(item, job);
            return;
        } catch (err) {
            if (attempt < 1) {
                return this.processWithRetry(item, job, attempt++);
            } else throw err;
        }
    }
}

