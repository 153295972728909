import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { HttpPendingService } from 'src/app/services/http-pending/http-pending.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-global-spinner',
  templateUrl: './global-spinner.component.html',
  styleUrls: ['./global-spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GlobalSpinnerComponent implements OnInit {

  showSpinner$ = new Subject<boolean>();

  constructor(pendingService: HttpPendingService) {
   pendingService.isPending.subscribe((v) => {
     setTimeout(() => {
        this.showSpinner$.next(v);
     }, 1);
   });
  }

  ngOnInit() {
  }

}
