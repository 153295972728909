import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import {FormsModule} from '@angular/forms';
import { TextAreaEditorComponent } from './textarea-editor/textarea-editor.component';
import { InputEditorComponent } from './input-editor/input-editor.component';
import { SelectEditorComponent } from './select-editor/select-editor.component';
import { DisplayFieldNameFilter } from './pipes/displayvalue.pipe';
import { DisplayNameFilter } from './pipes/displayname.pipe';



@NgModule({
  declarations: [
    TextAreaEditorComponent,
    InputEditorComponent,
    SelectEditorComponent,
    DisplayFieldNameFilter,
    DisplayNameFilter
  ],
  imports: [
    CommonModule,
    FormsModule,
  ],
  exports: [
    TextAreaEditorComponent,
    InputEditorComponent,
    SelectEditorComponent]
})
export class InlineModule { }
