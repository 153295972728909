<div class="m-t-10">
    <div *ngIf="!failed" class="d-flex justify-content-center">
        <div [class]="size === 'large' ? 'spinner-border' : 'spinner-border spinner-border-sm'" role="status">
        </div>
        <div *ngIf=loadingText class="m-l-10 m-t-5">{{loadingText}}</div>
    </div>
    <div *ngIf="failed" class="row justify-content-center">
        <div class="col">
            <div class="row justify-content-center">
                <p>{{ retryText }}</p>
            </div>
            <div class="row justify-content-center">
                <button class="btn btn-primary" (click)="retryRequest()">Retry</button>
            </div>
        </div>
    </div>
</div>
