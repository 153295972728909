
import { Component, AfterViewInit, Output, ViewChild, EventEmitter, Input } from '@angular/core';
import { IonSlides } from '@ionic/angular';
import { TemplateSlide } from 'src/app/models/template-slide';

@Component({
  selector: 'app-stepper-modal',
  templateUrl: './stepper-modal.component.html',
  styleUrls: ['./stepper-modal.component.scss']
})
export class StepperModalComponent implements AfterViewInit {

  @Input() title = '';
  @Input() templates: Array<TemplateSlide> = [];
  @Input() valid = true;
  @Input() pager = true;

  @Output() cancel = new EventEmitter();
  @Output() endForm = new EventEmitter();

  @Input() currentTemplate: TemplateSlide;
  @Output() currentTemplateChange = new EventEmitter();

  @ViewChild('slider') sliderComponent: IonSlides;

  sliderOpts = {
    initialSlide: 0,
  };

  isEnd = false;
  isStart = true;
  submitted = false;

  index = 0;

  constructor() {}

  ngAfterViewInit() {
    setTimeout(() => {
      this.sliderComponent.lockSwipes(true);
      this.isEnd = this.templates.length === 1;
      this.setCurrent();
      // this is here to make sure the swiper instance is always up to date.
      // without it, we sometimes get locked on the first slide.
      this.sliderComponent.update().then(_ => {
        this.sliderComponent.length().then((n) => {
          if (n===0){
            this.sliderComponent.update();
          }
        });
      });
    });

  }

  async leftHandler() {
    if (this.isStart) {
      this.cancel.emit();
    } else {
      this.prev();
    }
  }

  async rightHandler() {
    if (this.isEnd) {
      this.submitForm();
    } else {
      this.next();
    }
  }

  async slideHandler($event) {
    this.index = await this.sliderComponent.getActiveIndex();
    this.isEnd = this.templates.length === this.index + 1;
    this.isStart = this.index === 0;
    await this.setCurrent();
  }

  async setCurrent() {
    this.index = await this.sliderComponent.getActiveIndex();
    this.currentTemplate = this.templates[this.index];
    this.currentTemplateChange.emit(this.currentTemplate);
  }

  next() {
    this.sliderComponent.lockSwipes(false);
    this.sliderComponent.slideNext();
    this.sliderComponent.lockSwipes(true);
  }

  prev() {
    this.sliderComponent.lockSwipes(false);
    this.sliderComponent.slidePrev();
    this.sliderComponent.lockSwipes(true);
  }

  submitForm() {
    this.submitted = true;
    this.endForm.emit();
  }



}
