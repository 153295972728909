import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvService } from 'src/app/services/env/env.service';

import { JobManager } from 'src/app/abstract/job-manager';
import { UploadJob } from 'src/app/models/upload-job';
import { map } from 'rxjs/operators';
import { ConnectionStateService } from '../connection-state/connection-state.service';

@Injectable({
  providedIn: 'root',
})
export class MediaUploadManagerService extends JobManager {
  jobType = 'upload';
  jobIcon = 'cloud-upload-outline';
  cancelWarning: 'All images which have been successfully uploaded so far will be kept. The current image will finish before cancelling.';

  constructor(
    private http: HttpClient,
    private envService: EnvService,
    connection: ConnectionStateService
  ) {
    super(connection);
  }

  async setupJob(job: UploadJob) {
    const galleries = job.metadata.subjects.filter((s) => s.new);
    const existing = job.metadata.subjects.filter((s) =>! s.new);
    let newGalleries = [];
    if (galleries && galleries.length > 0 ) {
      newGalleries = await this.http
        .post<Array<any>>(
          this.envService.env.api + 'galleries',
          { galleries, event: job.metadata.eventId, member: job.member }
        )
        .toPromise();
    }
    job.metadata.subjects = newGalleries.concat(existing);

    const { sessionId } = await this.http
      .get<{ sessionId: string }>(
        this.envService.env.api + 'images/get-upload-session/' + job.metadata.eventId,
      ).toPromise();
    job.metadata.session = sessionId;
    return job;
  }

  async processItem(file: File, job: UploadJob) {
    const metadata = { ...job.metadata };

    const key = Date.now() + '_' + file.name;
    metadata.cameraPath = key;

    metadata.timestamp = new Date().getTime();

    let uploadType = 'console-image';
    let isImage = true;
    if (file.type) {
      isImage = file.type.includes('image');
      uploadType = isImage ? 'console-image' : 'console-video';
    }

    if (isImage) {
      metadata.orientation = await this.getImageDimensions(file);
    } else {
      metadata.orientation = await this.getVideoDimensions(file);
    }

    const awsPresignedMeta = {
      'x-amz-meta-ei-metadata': JSON.stringify(metadata),
    };

    const response = await this.http
      .post<{ url: string }>(
        this.envService.env.api + 'images/get-presigned-url',
        {
          uploadType,
          key,
          contentType: file.type,
          metadata: awsPresignedMeta,
          event: metadata.eventId,
        }
      )
      .toPromise();

    const headers = {
      Anonymous: '1', // Stops http auth interceptor kicking in
      'x-amz-meta-x-amz-meta-ei-metadata': JSON.stringify(metadata),
    };
    await this.http.put<any>(response.url, file, { headers }).toPromise();
  }

  async getImageDimensions(file) {
    return new Promise<number>((resolve) => {
      // create the video element
      let image = new Image();
      // place a listener on it
      image.onload = (event) => {
        // retrieve dimensions
        const t = event.target as HTMLImageElement;
        image = null;
        resolve(t.width > t.height ? 1 : 8);
      };
      // start download meta-datas
      image.src = URL.createObjectURL(file);
    });
  }

  async getVideoDimensions(file) {
    return new Promise<number>((resolve) => {
      // create the video element
      let video = document.createElement('video');
      // place a listener on it
      video.onloadedmetadata = (event) => {
        // retrieve dimensions
        const t = event.target as HTMLVideoElement;
        video = null;
        resolve(t.videoWidth > t.videoHeight ? 1 : 8);
      };
      // start download meta-datas
      video.src = URL.createObjectURL(file);
    });
  }
}
