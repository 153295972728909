import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { EIUser } from '../../models/user';
import { NGXLogger } from 'ngx-logger';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private readonly userSource = new BehaviorSubject<any>({});
  // eslint-disable-next-line @typescript-eslint/member-ordering
  readonly user$: Observable<EIUser> = this.userSource.asObservable()
    .pipe(distinctUntilChanged((prev, curr) => curr && prev ? prev.token === curr.token : false));

  constructor(private logger: NGXLogger) {
    this.user$.subscribe((val => {
      this.logger.info('Set user to', val);
    }));
  }

  get user(): EIUser {
    return this.userSource.getValue();
  }

  set user(val: EIUser) {
    this.userSource.next(val);
  }

  isAdmin(): Observable<boolean> {
    return this.user$.pipe(filter((user) => !!user), map((user: any) => user.isAdmin));
  }

  isManager(): Observable<boolean> {
    return this.user$.pipe(filter((user) => !!user), map((user: any) => user.isManager));
  }

  isRegularUser(): Observable<boolean> {
    return this.user$.pipe(filter((user) => !!user), map((user: any) => !user.isManager && !user.isAdmin));
  }
}
