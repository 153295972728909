import Rekognition from 'aws-sdk/clients/rekognition';
import { Observable } from 'rxjs';

export interface GalleryFilter {
    type: FilterType;
    value: string | number | boolean;
    data?: FilterData; //
}

export enum FilterType {
    FACE = 'face',
    NO_IDS = 'noIDs',
    PREVIEW = 'preview',
    DEVICE = 'device',
    VIDEO = 'video',
    DELETED = 'deleted',
    PURCHASED = 'purchased'
}

export interface FilterData {
    label?: string,
    img?: string // url to thumbnail
    faceBoundingBox?: Rekognition.BoundingBox
}

export interface MediaFaceRecord extends Rekognition.FaceRecord {
    timestamp?: number,
    thumbUrl?: string,
    galleries$?: Observable<any>
  }
