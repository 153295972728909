import { Component, Input, Output, EventEmitter, AfterViewInit, OnDestroy } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Observable, Subscription } from 'rxjs';
import { SearchSelectModalComponent } from '../../modals/search-select-modal/search-select-modal.component';
import { SelectOption } from 'src/app/models/select-option';

@Component({
  selector: 'app-search-select',
  templateUrl: './search-select.component.html',
  styleUrls: ['./search-select.component.scss']
})
export class SearchSelectComponent implements AfterViewInit, OnDestroy {

  @Input() value: any;
  @Output() optionChange = new EventEmitter<string>();
  @Output() valueChange = new EventEmitter<any>();

  @Input() deleteEventId: any;
  @Output() deleteEventIdChange = new EventEmitter<any>();

  displayValue;

  @Input() placeholder;
  @Input() list: Observable<SelectOption[]>;

  @Input() displayMode = 'search'; // or 'setting'
  @Input() stuck = false;
  @Input() settingLabel = 'Event:';

  listSub: Subscription;

  constructor(private modalCtrl: ModalController) {

  }

  ngAfterViewInit() {
    this.listSub = this.list.subscribe((items) => {
      this.setValue(this.value, items);
    });
  }

  ngOnDestroy() {
    this.listSub.unsubscribe();
  }

  setValue(current, items) {
      const selected = items.filter((i) => i.key === current);
      setTimeout(() => {
      if (selected.length > 0) {
          this.displayValue = selected[0].value;
          // console.log(this.displayValue)
        } else {
          this.value = null;
          this.displayValue = null;
          this.valueChange.emit(this.value);
        }
      }, 0);
  }

  clear() {
    // Cleared the current event, set delete event id to be the current event id to remove from db
    this.deleteEventId = this.value;
    this.deleteEventIdChange.emit(this.deleteEventId);

    this.value = null;
    this.displayValue = null;
    this.valueChange.emit(this.value);
    this.optionChange.emit(this.value);
  }

  async choose(e) {
    const modal = await this.modalCtrl.create({
      component: SearchSelectModalComponent,
      componentProps: {
        list: this.list
      },
      cssClass: 'modal-search'
    });
    modal.onDidDismiss().then((result) => {
      if (result.data) {
        // If we're deleting an event, set the delete event id to be the current event id, unless it's already been set
        if (!this.deleteEventId && this.value) {
          this.deleteEventId = this.value;
          this.deleteEventIdChange.emit(this.deleteEventId);
        }

        this.value = result.data.key;
        this.displayValue = result.data.value;
        this.valueChange.emit(this.value);
        this.optionChange.emit(this.value);
      }
    });
    await modal.present();
  }

}
