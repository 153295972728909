import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from './components/components.module';
import { PipesModule } from './pipes/pipes.module';
import { ModalsModule } from './modals/modals.module';
import { DirectivesModule } from './directives/directives.module';

@NgModule({
  imports: [
    CommonModule,
    ComponentsModule,
    PipesModule,
    ModalsModule,
    DirectivesModule
  ],
  exports: [
    ComponentsModule,
    ModalsModule,
    PipesModule,
    DirectivesModule
  ]
})
export class SharedModule { }
