import { Injectable } from '@angular/core';
import { GalleryService } from 'src/app/abstract/gallery-service';
import { HttpClient } from '@angular/common/http';
import { EnvService } from 'src/app/services/env/env.service';
import { MessageService } from 'src/app/services/message/message.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AttendeeGalleryService extends GalleryService {

  url = 'galleries/attendees/';
  devicesUrl = 'galleries/active-devices/attendees/';

  constructor(
    http: HttpClient,
    envService: EnvService,
    router: Router,
    messages: MessageService) {
      super(router, http, envService, messages);
    }

    open(id: string) {
      null;
    }

}
