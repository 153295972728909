import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { GalleryDetail } from 'src/app/models/gallery-detail';

@Component({
  selector: 'app-subject-chips',
  templateUrl: './subject-chips.component.html',
  styleUrls: ['./subject-chips.component.scss']
})
export class SubjectChipsComponent implements OnInit {

  @Input() subjects: GalleryDetail[] = [];
  @Input() action = 'open';
  @Input() color;
  @Output() clicked = new EventEmitter<GalleryDetail>();
  @Output() reassign  = new EventEmitter<string>();

  constructor(private router: Router,) { }

  ngOnInit() {
  }

  chipAction(subject) {
    if (this.action === 'open') {
      this.router.navigate(['galleries', subject.__t.toLowerCase()+'s', subject.id]);
    } else {
      this.clicked.emit(subject);
    }
  }

  iconColor() {
    if (this.color === 'danger') {
      return 'white';
    } else {
      return '';
    }
  }

  actionIconColor() {
    if (this.color === 'danger') {
      return 'white';
    } else {
      return 'danger';
    }
  }

  textColor() {
    if (this.color === 'danger') {
      return 'white';
    } else {
      if (this.action === 'open') {
        return 'primary';
      } else {
        return '';
      }
    }
  }

}
