import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { CognitoService } from 'src/app/services/cognito/cognito.service';
import { NGXLogger } from 'ngx-logger';

@Injectable({
  providedIn: 'root'
})
export class NavGuard implements CanActivate {

  constructor(
    private router: Router,
    private cognito: CognitoService,
    private logger: NGXLogger) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
    ): Promise<boolean | UrlTree> {
    return new Promise<boolean | UrlTree>((resolve) => {
      this.cognito.checkifUserLoggedIn()
        .then(_ => {
          if (state.url.includes('login')) {
            this.logger.info('Sending to dashboard');
            resolve(this.router.parseUrl('/dashboard'));

          } else {
            resolve(true);
          }
        })
        .catch(err => {
          this.logger.error(err);
          if (state.url.includes('login') || (err && err.code === 'NetworkError')) {
            resolve(true);
          } else {
            resolve(this.router.parseUrl('/login'));
          }
        });
    });
  }

}
