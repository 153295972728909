/* eslint-disable @typescript-eslint/member-ordering */
import { HttpClient } from '@angular/common/http';
import { EnvService } from '../env/env.service';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, of } from 'rxjs';
import { take, map } from 'rxjs/operators';
import { NGXLogger } from 'ngx-logger';
import { MessageService } from '../message/message.service';
import { UserService } from '../user/user.service';
import { SelectOption } from 'src/app/models/select-option';

// Jonathon Martin, Elementice, November 2019
@Injectable({
  providedIn: 'root',
})
export class IdsService {
  private readonly _members = new ReplaySubject<any>(1);
  private readonly _events = new ReplaySubject<any>(1);
  private readonly _devices = new ReplaySubject<any>(1);
  private readonly _clients = new ReplaySubject<any>(1);
  private readonly _templates = new ReplaySubject<any>(1);
  private readonly _integrations = new ReplaySubject<any>(1);

  readonly members$ = this._members.asObservable();
  readonly events$ = this._events.asObservable();
  readonly devices$ = this._devices.asObservable();
  readonly clients$ = this._clients.asObservable();
  readonly templates$ = this._templates.asObservable();
  readonly integrations$ = this._integrations.asObservable();

  constructor(
    private http: HttpClient,
    private envService: EnvService,
    private logger: NGXLogger,
    private messages: MessageService,
    private userService: UserService
  ) {
    this.refreshEvents = this.refreshEvents.bind(this);
    this.refreshMembers = this.refreshMembers.bind(this);
    //this.refreshClients = this.refreshClients.bind(this);
    this.refreshTemplates = this.refreshTemplates.bind(this);
    this.refreshIntegrations = this.refreshIntegrations.bind(this);
    this.refreshDevices = this.refreshDevices.bind(this);

    this.logger.info('Started IdsService');

    this.userService.user$.subscribe((user) => {
      if (user && user.uid) {
        this.refreshAll();
      }
    });

    this.messages.subscribe('change', (model) => {
      if (model === 'event') {
        this.refreshEvents();
      } else if (model === 'member') {
        this.refreshMembers();
      } else if (model === 'device') {
        this.refreshDevices();
      } else if (model === 'client') {
        this.refreshClients();
      } else if (model === 'template') {
        this.refreshTemplates();
      } else if (model === 'integration') {
        this.refreshIntegrations();
      } else if (model === 'all') {
        this.refreshAll();
      }
    });
  }

  refreshAll() {
    this.logger.info('Getting all ids');
    this.refreshMembers();
    this.refreshEvents();
    this.refreshDevices();
    //this.refreshClients();
    this.refreshTemplates();
    this.refreshIntegrations();
  }

  refreshMembers(debounce = 500) {
    this.logger.info('Getting members...');
    this.http
      .get<any[]>(this.envService.env.api + 'members/ids')
      .pipe(take(1))
      .subscribe(
        (members) => {
          this._members.next(members);
        },
        (_) => {
          this.refreshErrorHandler(debounce, this.refreshMembers);
        }
      );
  }
  refreshEvents(debounce = 500) {
    this.logger.info('Getting events...');
    this.http
      .get<any[]>(this.envService.env.api + 'events/ids')
      .pipe(take(1))
      .subscribe(
        (events) => {
          this._events.next(events);
        },
        (_) => {
          this.refreshErrorHandler(debounce, this.refreshEvents);
        }
      );
  }
  refreshDevices(debounce = 500) {
    this.logger.info('Getting devices');
    this.http
      .get<any[]>(this.envService.env.api + 'devices/ids')
      .pipe(take(1))
      .subscribe(
        (devices) => {
          this._devices.next(devices);
        },
        (_) => {
          this.refreshErrorHandler(debounce, this.refreshDevices);
        }
      );
  }
  refreshClients(debounce = 500) {
    this.logger.info('Getting clients');
    this.http
      .get<any[]>(this.envService.env.apiUrl + 'client/ids')
      .pipe(take(1))
      .subscribe(
        (clients) => {
          this._clients.next(clients);
        },
        (_) => {
          this.refreshErrorHandler(debounce, this.refreshClients);
        }
      );
  }
  refreshTemplates(debounce = 500) {
    this.logger.info('Getting templates');
    this.http.get<any[]>(this.envService.env.api + 'templates/ids').pipe(take(1)).subscribe(
      templates => { this._templates.next(templates); },
      _ => {
        this.refreshErrorHandler(debounce, this.refreshTemplates);
      });
  }
  refreshIntegrations(debounce = 500) {
    this.logger.info('Getting integrations');
    this.http
      .get<any[]>(this.envService.env.apiUrl + 'integration/ids')
      .pipe(take(1))
      .subscribe(
        (integrations) => {
          this._integrations.next(integrations);
        },
        (_) => {
          this.refreshErrorHandler(debounce, this.refreshIntegrations);
        }
      );
  }

  eventHasIntegration(eventId: string, service: string): Observable<boolean> {
    return this.events$.pipe(
      map((events) => {
        const event = events.find((ev) => ev.id === eventId);
        return (
          event && event.integrations && event.integrations.includes(service)
        );
      })
    );
  }

  getIntegrationsForMember(memberId): Observable<any[]> {
    return this.integrations$.pipe(
      map((integrations) =>
        integrations.filter((int) => int.memberId === memberId)
      )
    );
  }

  getIntegrationFriendlyName(service: string): string {
    if (service.length > 3) {
      return service
        .split('_')
        .map((s) => s.charAt(0).toUpperCase() + s.slice(1).toLowerCase())
        .join(' ');
    } else {
      return service;
    }
  }

  getParentIntegration(memberId, service): Observable<any> {
    return this.integrations$.pipe(
      map(
        (integrations) =>
          integrations.filter(
            (int) => int.memberId === memberId && int.service === service
          )[0]
      )
    );
  }

  getParentIntegrationById(id: string): Observable<any> {
    return this.integrations$.pipe(
      map((integrations) => integrations.filter((int) => int._id === id)[0])
    );
  }

  getDevicesForSelect(memberId?): Observable<SelectOption[]> {
    return this.devices$.pipe(
      map((devices) => {
        if (memberId) {
          devices = devices.filter((de) => de.memberId === memberId);
        }
        return devices
          .map((device) => ({ key: device.id, value: device.name }))
          .sort(this.selectSort);
      })
    );
  }

  getEventsForSelect(memberId?): Observable<SelectOption[]> {
    return this.events$.pipe(
      map((events) => {
        if (memberId) {
          events = events.filter((ev) => ev.memberId === memberId);
        }
        return events
          .map((event) => ({
            key: event.id,
            value: event.name,
            member: event.memberId,
          }))
          .sort(this.selectSort);
      })
    );
  }

  getClientsForSelect(memberId?): Observable<SelectOption[]> {
    return this.clients$.pipe(
      map((clients) => {
        if (memberId) {
          clients = clients.filter((cl) => cl.memberId === memberId);
        }
        return clients
          .map((client) => ({ key: client.id, value: client.name }))
          .sort(this.selectSort);
      })
    );
  }

  getTemplatesForSelect(memberId?): Observable<SelectOption[]> {
    return this.templates$.pipe( map( templates => {
      if (memberId) {
        templates = templates.filter(cl => cl.memberId === memberId);
      }
      return templates.map((template) => ( { key: template.id, value: template.name } ) ).sort(this.selectSort); } ) );
  }

  getMembersForSelect(): Observable<SelectOption[]> {
    return this.members$.pipe(
      map((members) =>
        members
          .map((member) => ({ key: member.id, value: member.name }))
          .sort(this.selectSort)
      )
    );
  }

  getDeviceById(id): Observable<any> {
    return this.devices$.pipe(
      map((devices) => devices.filter((device) => device.id === id)[0])
    );
  }

  getEventById(id): Observable<any> {
    return this.events$.pipe(
      map((events) => events.filter((event) => event.id === id)[0])
    );
  }

  getMemberById(id): Observable<any> {
    return this.members$.pipe(
      map((members) => members.filter((member) => member.id === id)[0])
    );
  }

  getTemplateById(id): Observable<any> {
    return this.templates$.pipe(map( templates => templates.filter(template => template.id === id)[0] ) );
  }

  getClientById(id): Observable<any> {
    return this.clients$.pipe(
      map((clients) => clients.filter((client) => client.id === id)[0])
    );
  }

  getDeviceName(id): Observable<string> {
    return this.getDeviceById(id).pipe(
      map((device) => (device ? device.name : ''))
    );
  }

  getEventName(id): Observable<string> {
    return this.getEventById(id).pipe(
      map((event) => (event ? event.name : ''))
    );
  }

  getMemberName(id): Observable<string> {
    return this.getMemberById(id).pipe(
      map((member) => (member ? member.name : ''))
    );
  }

  getClientName(id): Observable<string> {
    return this.getClientById(id).pipe(
      map((client) => (client ? client.name : ''))
    );
  }

  getTemplateName(id): Observable<string> {
    return this.getTemplateById(id).pipe(map(template =>  template ? template.name : ''));
  }

  selectSort(a, b) {
    if (a && b) {
      return a.value.toUpperCase() < b.value.toUpperCase() ? -1 : 1;
    }
    return 0;
  }

  refreshErrorHandler(debounce, callback) {
    if (debounce < 5000) {
      setTimeout(() => {
        this.logger.log('Retrying ids request...');
        callback(debounce * 2);
      }, debounce);
    }
  }
}
