

<ion-header>
    <ion-toolbar>
        <ion-searchbar inputmode=search #searchbar debounce=0 (ionChange)=setSearch($event)></ion-searchbar>
    </ion-toolbar>

</ion-header>

<ion-content>
    <ion-grid>
        <ion-row>
            <ion-col>
                <ion-list>
                    <ion-item readonly (click)="select(option)" *ngFor="let option of (filteredList$ | async)">
                        <ion-label [color]="((item) && option.key === item.key) ? 'primary' : ''">{{option.value}}</ion-label>
                        <ion-icon slot=end color=primary *ngIf="(item) && option.key === item.key" name=checkmark></ion-icon>
                    </ion-item>
                </ion-list>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-content>

<app-modal-footer
(left)=cancel()
leftLabel=Cancel
(right)=confirm()
rightLabel="Select"
[rightDisabled]=!item>
</app-modal-footer>
