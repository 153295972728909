import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvService } from '../env/env.service';
import { NGXLogger } from 'ngx-logger';
import { ModalController } from '@ionic/angular';
import { ToastService } from '../toast/toast.service';
import { DropboxConfigComponent } from 'src/app/shared/modals/integration-config-dropbox/integration-config-dropbox.component';
import { IntegrationBuilder } from 'src/app/abstract/integration-builder';
import { EIEvent } from 'src/app/models/event';

@Injectable({
  providedIn: 'root'
})
export class DropboxService implements IntegrationBuilder {

  constructor(
    private http: HttpClient,
    private envService: EnvService,
    private logger: NGXLogger,
    private modalCtrl: ModalController,
    private toast: ToastService) { }

  public saveIntegration(memberId, code) {
    const dropboxConfig = {
      memberId,
      code,
      redirect_uri: this.envService.env.redirectUri
    };
    this.http.post(this.envService.env.api + 'integrations/dropbox-auth', dropboxConfig).toPromise().then(() => {
      this.toast.success('Integration with DROPBOX successful!');
    }).catch(() => {
      this.toast.error('Error connecting to DROPBOX');
    });
  }

  public failIntegration() {
      this.toast.error('Error connecting to DROPBOX');
  }

  public async newConfigModal(event: EIEvent, config?) {
    return new Promise(async (resolve) => {
      const modal = await this.modalCtrl.create({
        component: DropboxConfigComponent,
        componentProps: {
          event,
          getNewImageName: this.getNewImageName,
          config
        }
      });
      modal.onDidDismiss().then((response) => {
        resolve(response.data);
      }).catch(() => {
        //resolve();
        this.logger.info('Dropbox modal dismissed');
      });
      await modal.present();
    });

  }

  getNewImageName(eventName, integration) {
    const imageNameParts = [];
    if (integration.imageName.device) {
      if (integration.imageName.unsafe) {
        imageNameParts.push('0');
      } else {
        imageNameParts.push('Walker0');
      }

    }
    if (integration.imageName.event) {
      imageNameParts.push(eventName.split(' ').join('_'));
    }
    if (integration.imageName.attendee && integration.sortBy !== 'none') {
      imageNameParts.push('firstName', 'lastName');
    }
    const ISOString = (new Date()).toISOString().split('.')[0].split(':').join('-');
    if (integration.sortBy !== 'timestone') {
      if (integration.imageName.timestamp) {
        imageNameParts.push(ISOString);
      }
      imageNameParts.push(['abcdef123456']);
    } else {
      if (integration.imageName.unsafe) {
        imageNameParts.push(ISOString.split('-').join(''));
            imageNameParts.push(['abcd']);
        return imageNameParts.join('_') + '(ABC123)';
      } else {
        imageNameParts.push(ISOString);
        imageNameParts.push(['abcdef123456']);
        return imageNameParts.join('_') + '(ABC123)';
      }

    }
    return imageNameParts.join('_');
  }
}
