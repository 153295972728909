import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(private alertCtrl: AlertController) { }

  async info(header: string, message?: string) {
    const alert = await this.alertCtrl.create({
      header,
      message,
      buttons: ['OK']
    });
    await alert.present();
  }

  async affirmative(header: string, confirmText: string, message?: string): Promise<boolean> {
      const alert = await this.alertCtrl.create({
        header,
        message,
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary'
          }, {
            text: confirmText
          }
        ]
      });
      await alert.present();
      const result = await alert.onWillDismiss();
      return result.role !== 'cancel';
  }

  async negative(header: string, confirmText: string, message?: string, cancelText?: string) {
      const alert = await this.alertCtrl.create({
        header,
        message,
        buttons: [
          {
            text: cancelText ? cancelText : 'Cancel',
            role: 'cancel',
            cssClass: 'secondary'
          }, {
            text: confirmText,
            role: 'confirm',
          }
        ]
      });
      await alert.present();
      const result = await alert.onWillDismiss();
      return result.role === 'confirm';
  }

  // resolves with the text of the option given
  choice(header: string, choiceTextOne: string, choiceTextTwo: string, message?: string) {
    return new Promise(async (resolve) => {
      const alert = await this.alertCtrl.create({
        header,
        message,
        buttons: [
          {
            text: choiceTextOne,
            handler: () => {
              resolve(choiceTextOne);
            }
          },  {
            text: choiceTextTwo,
            handler: () => {
              resolve(choiceTextTwo);
            }
          }
        ]
      });
      await alert.present();
    });

  }

  select(header, confirmText, options: any[]) {
    const radioOptions = options.map((opt) => ({
        ...opt,
        type: 'radio',
        name: opt.label
      }));
    return new Promise<string>(async (resolve) => {
      const alert = await this.alertCtrl.create({
        header,
        inputs: radioOptions,
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary'
          }, {
            text: confirmText,
            handler: (result) => {
              resolve(result);
            }
          }
        ]
      });
      await alert.present();
    });
  }
}
