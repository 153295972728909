<app-modal-header title="New Dropbox Integration"></app-modal-header>

<ion-content>
  <form [formGroup]="newDropboxConfigForm">
    <ion-grid>
      <ion-row>
        <ion-col size=4>
          <ion-label>Save images in folder named:</ion-label>
        </ion-col>
          <ion-col size=8>
            <input class="form-control" formControlName=folderName required name="folderName"/>
            <small>If this folder does not exist, it will be created.</small>
          </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size=4>
          <ion-label>Sort images by:</ion-label>
        </ion-col>
          <ion-col size=8>
            <select class="form-control text-capitalize" formControlName=sortBy required name="sortBy">
              <option class="text-capitalize" *ngFor="let sortType of sortOptions">{{sortType}}</option>
            </select>
            <small *ngIf="newDropboxConfigForm.controls.sortBy.value === 'gallery'">
                Images will be sorted into folders named for the IDs they are associated with.
                If an image has more than one ID, it will appear in each folder. 
            </small>
            <small *ngIf="newDropboxConfigForm.controls.sortBy.value === 'timestone'">
              If an image has more than one ID, it will be duplicated for each. Timestone sort always contains a timestamp.
            </small>
            <small *ngIf="newDropboxConfigForm.controls.sortBy.value === 'none'">
                Images will not be sorted into folders. Attendee naming has no effect in this mode.
            </small>
          </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size=4>
          <ion-label>Name images using:</ion-label>
        </ion-col>
          <ion-col size=8 formGroupName=imageName>
            <div class="d-inline-block p-r-10 m-l-20" style="width: 60px">
              <input type="checkbox" class="form-check-input" formControlName=event>
              <label class="form-check-label">Event</label>
            </div>
            <div class="d-inline-block p-r-10 m-l-20" style="width: 80px">
              <input type="checkbox" class="form-check-input" formControlName=device>
              <label class="form-check-label">Device</label>
            </div>
            <div class="d-inline-block p-r-10 m-l-20" style="width: 110px" >
              <input type="checkbox" class="form-check-input" formControlName=timestamp>
              <label class="form-check-label">Timestamp</label>
            </div>
            <div class="d-inline-block p-r-10 m-l-20" style="width: 100px" >
              <input type="checkbox" class="form-check-input" formControlName=attendee>
              <label class="form-check-label">Attendee</label>
            </div>
            <div class="d-inline-block p-r-10 m-l-20" *ngIf="newDropboxConfigForm.controls.sortBy.value === 'timestone'">
              <input type="checkbox" class="form-check-input" formControlName=unsafe>
              <label class="form-check-label">Unsafe Mode</label>
            </div>
            <small class=d-block>
              If attendee is selected, but no attendee is found, nothing will be added.
              <br> e.g. {{exampleImageName$ | async}}
            </small>
          </ion-col>
      </ion-row>
    </ion-grid>
  </form>
</ion-content>

<app-modal-footer
(left)=close()
[rightDisabled]=newDropboxConfigForm.invalid
(right)=save()>
</app-modal-footer>
  