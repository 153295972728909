export const environment = {
    production: false,
    titleSuffix: ' (Dev)',
    version: require('../../package.json').version + '-dev',
    apiUrl: '/api/',
    api: 'https://dev-api.elementice.com/',
    cardFrontUrl: '/card-fronts/',
    socketUrl: 'https://c9.elementice.com/consoles',
    zipsBucketUrl: 'https://s3-ap-southeast-2.amazonaws.com/ei-zips-dev-ap-southeast-2/',
    watermarksBucketUrl: 'https://s3-ap-southeast-2.amazonaws.com/ei-watermarks-dev-ap-southeast-2/',
    galleryUrl: 'https://c9.elementice.com:8080',
    eventbriteKey: 'HMYJ6QHMES3QB2H33P',
    stripeConnectUrl:
    'https://connect.stripe.com/oauth/authorize?response_type=code&client_id=ca_FZUiM1xwSaiFHMEYcfqat6oa7TncD8BG&scope=read_write',
    dropboxConnectUrl:
    'https://www.dropbox.com/oauth2/authorize?client_id=il9vmlfkznzszbn&response_type=code&token_access_type=offline&redirect_uri=',
    xeroAuthUrl: 'https://login.xero.com/identity/connect/authorize?response_type=code&client_id=7010C2C1D06C40BEB632D77AAEE32FAD' +
        '&scope=openid profile email accounting&redirect_uri=',
    twilioConnectUrl: 'https://www.twilio.com/authorize/CNeaf89cdcc810139f48339e4d4a12391f',
    redirectUri: 'https://c9.elementice.com/dashboard',
    awsUserPoolId: 'ap-southeast-2_VdWaUJW0k',
    awsClientId: '66rkov64e104m5gnppch6gcjls',
    awsRegion: 'ap-southeast-2',
    awsIdentityPoolId: 'ap-southeast-2:a40b39c3-e313-404d-8edb-4550701d1f92',
    fotomerchantAdminUrl: 'https://admin.staging.fotomerchanthv.com/',
    fotomerchantApiUrl: 'https://api.staging.fotomerchanthv.com/',
    referenceImageBucket: 'https://s3-ap-southeast-2.amazonaws.com/ei-reference-faces-dev-ap-southeast-2/',
    googleAnalyticsID: 'G-ST7XQVVLBM',
    googleAnalyticsUrl: 'https://www.googletagmanager.com/gtag/js?id=G-ST7XQVVLBM',
    ipStackCheck: 'https://api.ipstack.com/check?access_key=e96ecdbd6a5e6b706be003dc3f8fdce2&fields=country_code',
    DEFAULT_PHONE_COUNTRY: 'AU',
    stripePaymentsUrl: 'https://dashboard.stripe.com/test/payments/'
};
