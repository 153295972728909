import { Injectable } from '@angular/core';
import { JobManager } from 'src/app/abstract/job-manager';
import { UploadJob } from 'src/app/models/upload-job';
import { AttendeeService } from '../attendee/attendee.service';
import { ConnectionStateService } from '../connection-state/connection-state.service';


@Injectable({
  providedIn: 'root'
})
export class BulkNotifyService extends JobManager {

  jobType = 'notify';
  jobIcon = 'notifications-outline';
  cancelWarning: 'Notifications cannot be un-sent. The current attendee will be notified before cancelling.';

  constructor(
    private service: AttendeeService,
    connection: ConnectionStateService
    ) {
      super(connection);
    }

    async setupJob(job: UploadJob) {
      return job;
    }

    async processItem(item: any, job: UploadJob) {
      await this.service.resendNotification(item._id, job.metadata.event);
    }

}
