import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NavGuard } from './guards/nav.guard';

const routes: Routes = [
    {
        path: 'login',
        loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
        canActivate: [NavGuard]
    },
    {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [NavGuard]
    },
    {
        path: 'admin-toolbox',
        loadChildren: () => import('./admin-toolbox/admin-toolbox.module').then(m => m.AdminToolboxModule),
        canActivate: [NavGuard]
    },
    {
        path: 'templates',
        loadChildren: () => import('./templates/templates.module').then(m => m.TemplatesModule),
        canActivate: [NavGuard]
    },
    {
      path: 'clients',
      loadChildren: () => import('./templates/templates.module').then(m => m.TemplatesModule),
      canActivate: [NavGuard]
    },
    {
        path: 'members',
        loadChildren: () => import('./members/members.module').then(m => m.MembersModule),
        canActivate: [NavGuard]
    },
    {
        path: 'devicelist',
        loadChildren: () => import('./devices/devices.module').then(m => m.DevicesModule),
        canActivate: [NavGuard]
    },
    {
        path: 'events',
        loadChildren: () => import('./events/events.module').then(m => m.EventsModule),
        canActivate: [NavGuard]
    },
    {
        path: 'galleries',
        loadChildren: () => import('./galleries/galleries.module').then(m => m.GalleriesModule),
        canActivate: [NavGuard]
    },
    {
        path: '',
        redirectTo: '/login',
        pathMatch: 'full'
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes)
    ],
    exports: [
        RouterModule
    ],
    declarations: []
})
export class AppRoutingModule { }
