<div #uploadarea class="upload-area" [ngClass]="{'drag-over': dragOver}" (click)=fileInput.nativeElement.click() (drop)="onFileDrop($event)" (dragover)="onDragOver($event)">
    
    <input class="file-input" #folderinput type="file" accept={{acceptedFiles}} (change)="onFileSelect($event)" webkitDirectory directory />
    <input class="file-input" #fileinput type="file" accept={{acceptedFiles}} (change)="onFileSelect($event)" multiple />

    <div [hidden]="files.length > 0 || dropped" class="drag-files">{{message}}</div>

    <div [hidden]="!(dropped && thumbs.length == 0)" class='spinner-border spinner-border-sm' role="status"></div>
    
    <div class="thumbs" [hidden]="thumbs.length == 0">
      <img *ngFor="let thumb of thumbs" [src]="thumb"/>
      <div [hidden]="thumbs.length == files.length" class="more-images">+ {{files.length - thumbs.length}} more.</div>
    </div>

  </div>