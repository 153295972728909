import io from 'socket.io-client';
import { Injectable } from '@angular/core';
import { EnvService } from '../env/env.service';
import { Router, NavigationStart } from '@angular/router';
import { MessageService } from '../message/message.service';
import { UserService } from '../user/user.service';
import { NGXLogger } from 'ngx-logger';


@Injectable({
    providedIn: 'root'
  })
export class SocketService {

    private socket: SocketIOClient.Socket;
    url = '';

    constructor(
        public envService: EnvService,
        router: Router,
        private messages: MessageService,
        private userService: UserService,
        private logger: NGXLogger
        ) {
        messages.subscribe('authenticated', () =>  {
            if (!this.socket || !this.socket.connected) {
                this.start(this.userService.user.token);
            }
        });
        router.events.subscribe(e => {
            if (e instanceof NavigationStart) {
                this.url = e.url.substring(1).split('?')[0];
                this.sendConsoleState();
            }
          });
    }

    public isConnected(): boolean {
        if (!this.socket) { return false; }
        return this.socket.connected;
    }

    public async emit(name: string, data: any, callback?) {
        data.queryData.invokedBy = this.userService.user.firstName + ' ' + this.userService.user.lastName;
        this.logger.info('SocketService emitting:', data);
        return new Promise((resolve) => {
            this.socket.emit(name, data, (response) => {
                if (callback) {
                    callback(response);
                }
                resolve(response);
            });
        });

    }

    public close() {
        if (this.socket) { this.socket.close(); }
    }

    private sendConsoleState() {
        if (this.socket) { this.socket.emit('query', {queryName: 'console.state', queryData: {url: this.url}});}
    }

    private start(token) {

        this.socket = io(this.envService.env.socketUrl, {secure: true, query: 'token=' + token});
        this.socket.on('connect', (data) => {
            this.logger.info('Sockets connected');
            this.sendConsoleState();
        });

        this.socket.on('change', (data) => {
            this.logger.info('Recieved change notification:', data);
            this.messages.broadcast('change', data.model);
        });

        this.socket.on('query', (data) => {
            // this.logger.info('SocketService recieved query:', data.queryName);
            const queryName = data.queryName;
            const queryData = data.queryData;

            const querySplit = queryName.split('.');
            if (querySplit.length > 2) {
                this.logger.warn('Query is too long. Use less dots.');
                return;
            }

            const queryFunctions = {
                newDevice: {
                    status: (broadcastData) => {
                        this.messages.broadcast('newDeviceStatus', broadcastData);
                    }
                },
                device: {
                    newImage: (broadcastData) => {
                        this.messages.broadcast('newImage', broadcastData);
                    },
                    status: (broadcastData) => {
                        this.messages.broadcast('deviceStatus', broadcastData);
                    },
                    serviceUpdate: (broadcastData) => {
                        this.messages.broadcast('serviceUpdate', broadcastData);
                    },
                    info: (broadcastData) => {
                        this.messages.broadcast('info', broadcastData);
                    },
                    logFile: (broadcastData) => {
                        this.messages.broadcast('logFile', broadcastData);
                    },
                    logFileTail: (broadcastData) => {
                        this.messages.broadcast('logFileTail', broadcastData);
                    }
                },
                deviceStatus: {
                    displayUpdate: (broadcastData) => {
                        this.messages.broadcast('displayUpdate', broadcastData);
                    },
                    updateStatus: (broadcastData) => {
                        this.messages.broadcast('updateStatus', broadcastData);
                    },
                    triggerStatus: (broadcastData) => {
                        this.messages.broadcast('triggerStatus', broadcastData);
                    },
                    cameraLinkStatus: (broadcastData) => {
                        this.messages.broadcast('cameraLinkStatus', broadcastData);
                    }
                }
            };


            if (querySplit.length === 1) {
                if (!queryFunctions.hasOwnProperty(querySplit[0])) {
                    this.logger.info('Unrecognised query.');
                    return;
                }
                queryFunctions[querySplit[0]](queryData);
                return;
            }
            if (querySplit.length === 2) {
                if (!queryFunctions.hasOwnProperty(querySplit[0])) {
                    this.logger.info('Unrecognised query.');
                    return;
                }
                if (!queryFunctions[querySplit[0]].hasOwnProperty(querySplit[1])) {
                    this.logger.info('Unrecognised subquery: ' + queryName + '.');
                    return;
                }

                queryFunctions[querySplit[0]][querySplit[1]](queryData);
                return;
            }
        });

    }

}

// var simulateQuery = function() {
    //     var statuses = {
    //           "1": {
    //               color: 'green',
    //               message: 'SCAN YOUR PASS!'
    //           },
    //           "2": {
    //               color: 'green',
    //               message: 'GO GO GO!'
    //           },
    //           "3": {
    //               color: 'amber',
    //               message: 'PROCESSING'
    //           },
    //           "4": {
    //               color: 'red',
    //               message: 'OFFLINE'
    //           }
    //       };
    //     this.logger.info("Sending query")
    //     setTimeout(function() {
    //         this.messages.broadcast('displayUpdate', {deviceId: "313322", data: {message: String(Math.floor(Math.random() * 4) + 1)}})
    //         simulateQuery();
    //     }, 2000)
    // }
    // this.logger.info("Starting query simulation")
    // simulateQuery();
