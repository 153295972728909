import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ModalController } from '@ionic/angular';
import { EnvService } from '../env/env.service';
import { NGXLogger } from 'ngx-logger';
import { NewIntegrationModalComponent } from 'src/app/shared/modals/integration-new/integrationNew.component';
import { ToastService } from '../toast/toast.service';
import { AlertService } from '../alert/alert.service';
import { INTEGRATION_SERVICE } from 'src/app/models/integration-service';

// Jonathon Martin, Elementice, August 2019

@Injectable({
  providedIn: 'root',
})
export class IntegrationService {
  // used to filter available integrations, so we don't double up
  existingIntegrations = [];

  // array of available integration services
  serviceOptions = Object.values(INTEGRATION_SERVICE);

  constructor(
    private envService: EnvService,
    private http: HttpClient,
    private modalCtrl: ModalController,
    private toast: ToastService,
    private alert: AlertService,
    private logger: NGXLogger
  ) {}

  saveIntegration(newIntegration, callback) {
    if (newIntegration.access_token) {
      newIntegration.token = newIntegration.access_token;
    }
    this.http
      .post(this.envService.env.apiUrl + 'integrations', newIntegration)
      .toPromise()
      .then((response: any) => {
        this.toast.success(
          'Integration with ' + newIntegration.service + ' successful!'
        );
        if (callback) {
          callback(response);
        }
      })
      .catch((response) => {
        this.toast.error('Error connecting to ' + newIntegration.service);
        if (callback) {
          callback(response);
        }
      });
  }

  async deleteIntegration(integration, callback) {
    const doDelete = await this.alert.negative(
      'Delete this integration?',
      'Yes, delete!'
    );
    if (!doDelete) {
      return;
    }
    this.http
      .delete(this.envService.env.api + 'integrations/' + integration._id)
      .toPromise()
      .then((result) => {
        callback(true);
      })
      .catch((err) => {
        this.logger.error(err);
        callback(false);
      });
  }

  async newIntegrationModal(memberId, memberIntegrations) {
    // this.logger.warn('New integration modal not implemented');
    this.existingIntegrations = memberIntegrations;
    const newIntegrationModal = await this.modalCtrl.create({
      component: NewIntegrationModalComponent,
      componentProps: {
        serviceOptions: this.serviceOptions,
        memberId,
        existingIntegrations: memberIntegrations,
      },
    });

    await newIntegrationModal.present();
    const newIntegration = await newIntegrationModal.onWillDismiss();
    if (newIntegration.data) {
      newIntegration.data.memberId = memberId;
      switch (newIntegration.data.service) {
        case INTEGRATION_SERVICE.FOTOMERCHANT:
          await this.newFotoMerchantIntegrationSave(newIntegration.data);
          return;
        case INTEGRATION_SERVICE.EI_MESSAGING:
          await this.newMessagingIntegrationSave(newIntegration.data);
          return;
        case INTEGRATION_SERVICE.FACIAL_REC:
          await this.newFacialRecIntegrationSave(newIntegration.data);
          return;
        case INTEGRATION_SERVICE.OCR:
          await this.newOCRIntegrationSave(newIntegration.data);
          return;
        case INTEGRATION_SERVICE.ENHANCEMENT:
          await this.newEnhancementIntegrationSave(newIntegration.data);
          return;
        case INTEGRATION_SERVICE.GFP:
          await this.newGFPIntegrationSave(newIntegration.data);
          return;
        default:
          this.logger.warn('Invalid service chosen');
          throw new Error('Invalid service chosen');
      }
    }
  }

  async newFotoMerchantIntegrationSave(newIntegration) {
    const addFMMemberRequest = {
      action: 'ADD_MEMBER_POLICY',
      payload: {
        integration: newIntegration,
      },
    };
    try {
      const fmResponse = await this.http
        .post<any>(
          this.envService.env.api + 'integrations/fotomerchant',
          addFMMemberRequest
        )
        .toPromise();
      newIntegration.storeUrl = fmResponse.member.store.url;
      return this.http
        .post(this.envService.env.api + 'integrations', newIntegration)
        .toPromise()
        .catch((error) => {
          this.toast.error('Error connecting to ' + newIntegration.service);
        });
    } catch (error) {
      console.error(error);
      this.toast.error('Error connecting to ' + newIntegration.service);
    }
  }

  async newMessagingIntegrationSave(newIntegration) {
    return this.http
      .post(this.envService.env.api + 'integrations', newIntegration)
      .toPromise()
      .catch((error) => {
        this.toast.error('Error connecting to ' + newIntegration.service);
        throw error;
      });
  }

  async newGFPIntegrationSave(newIntegration) {
    return this.http
      .post(this.envService.env.api + 'integrations/', newIntegration)
      .toPromise()
      .catch((error) => {
        this.toast.error('Error connecting to ' + newIntegration.service);
        throw error;
      });
  }

  async newOCRIntegrationSave(newIntegration) {
    return this.http
      .post(this.envService.env.api + 'integrations', newIntegration)
      .toPromise()
      .catch((error) => {
        this.toast.error('Error connecting to ' + newIntegration.service);
        throw error;
      });
  }

  async newFacialRecIntegrationSave(newIntegration) {
    return this.http
      .post(this.envService.env.api + 'integrations', newIntegration)
      .toPromise()
      .catch((error) => {
        this.toast.error('Error connecting to ' + newIntegration.service);
        throw error;
      });
  }

  async newEnhancementIntegrationSave(newIntegration) {
    return this.http
      .post(this.envService.env.api + 'integrations', newIntegration)
      .toPromise()
      .catch((error) => {
        this.toast.error('Error connecting to ' + newIntegration.service);
        throw error;
      });
  }
}
