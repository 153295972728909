import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvService } from '../env/env.service';
import { NGXLogger } from 'ngx-logger';
import { ToastService } from '../toast/toast.service';
import { IntegrationBuilder } from 'src/app/abstract/integration-builder';
import { EIEvent } from 'src/app/models/event';

@Injectable({
  providedIn: 'root'
})
export class StripeService implements IntegrationBuilder {

  constructor(
    private http: HttpClient,
    private envService: EnvService,
    private toast: ToastService) { }

  newConfigModal(event: EIEvent, config?) {
    throw new Error('Method not implemented.');
  }

  public saveIntegration(memberId, code, ) {
    const stripeConfig = {
      memberId,
      code
    };
    this.http.post(this.envService.env.apiUrl + 'integration/stripe-auth', stripeConfig).toPromise().then((response: any) => {
      this.toast.success('Integration with STRIPE successful!');
    }).catch((response) => {
      this.toast.error('Error connecting to STRIPE');
    });
  }

  public failIntegration() {
      this.toast.error('Error connecting to STRIPE');
  }
}
