import { Injectable } from '@angular/core';
import { Observable,  of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IdsService } from '../ids/ids.service';


@Injectable({
  providedIn: 'root'
})
export class ModuleService{

  constructor(
    private idsService: IdsService) {
    }

  canUse(module: string, memberId: number): Observable<boolean> {
    return this.idsService.getMemberById(memberId)
    .pipe(map(member => member && member.modules && member.modules.includes(module.toUpperCase())), catchError((err) => {
      console.log(err);
      return of(false);
    }));
  }

}
