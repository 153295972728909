import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-upload-area',
  templateUrl: './upload-area.component.html',
  styleUrls: ['./upload-area.component.scss']
})
export class UploadAreaComponent implements OnInit {

  @ViewChild('uploadarea') public uploadArea: ElementRef<HTMLElement>;
  @ViewChild('fileinput') public fileInput: ElementRef<HTMLElement>;
  @ViewChild('folderinput') public folderInput: ElementRef<HTMLElement>;

  @Input() acceptedFiles = ['.jpg', '.jpeg', '.mp4', '.mov'];

  @Input() message = 'Drag your files here or click to choose.';

  @Input() maxFiles;

  @Input() files: File[] = [];
  @Output() filesChange = new EventEmitter<File[]>();

  @Output() ready = new EventEmitter<boolean>();

  @Output() uploading = new EventEmitter<boolean>();

  dragOver = false;

  dropped = false;

  numberOfThumbs = 12;

  thumbs = [];

  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
  }

  async onFileDrop($event) {
    this.dragOver = false;
    $event.preventDefault();
    $event.stopPropagation();

    if (!$event.dataTransfer.items || $event.dataTransfer.items.length === 0) {
      return;
    }

    this.dropped = true;
    this.thumbs = [];

    // If we're not chrome, process just files, not folders...
    if (!$event.dataTransfer.items[0].webkitGetAsEntry) {
      this.filterAndAddFiles($event.dataTransfer.files);
      return;
    }
    const files = await this.getFilesFromFolderTree($event.dataTransfer.items);

    this.filterAndAddFiles(files);
  }

  onFileSelect($event) {
    console.log($event);
    if ($event.target.files.length === 0) {return;}
    this.filterAndAddFiles($event.target.files);
  }

  async getFilesFromFolderTree(items: DataTransferItemList): Promise<File[]> {
    return new Promise(async (resolve, reject) => {
      let files = [];
      const promises = [];

      // eslint-disable-next-line @typescript-eslint/prefer-for-of
      for (let i = 0; i < items.length; i++) {
        promises.push(this.traverseFileTree(items[i].webkitGetAsEntry(), null));
      }

      const returnedFiles = await Promise.all(promises);
      files = files.concat(...returnedFiles);
      resolve(files);
    });
  }

  async traverseFileTree(item, path) {
    return new Promise(async (resolve, reject) => {
      let files = [];
      path = path || '';
      if (item.isFile) {
        const file = await new Promise((res1, rej1) => {
          item.file(res1);
        });
        files.push(file);
      } else if (item.isDirectory) {
        // Get folder contents
        const dirReader = item.createReader();

        let entries = [];
        let returnedArraySize = -1;

        while (returnedArraySize !== 0) {
          const thisEntries = await new Promise<any>((res2, rej2) => {
            dirReader.readEntries(res2, rej2);
          });
          returnedArraySize = thisEntries.length;
          entries = entries.concat(thisEntries);
        }

        for (const e of entries) {
          const incomingFiles = await this.traverseFileTree(e, path + item.name + '/');
          files = files.concat(incomingFiles);
        }
      }
      resolve(files);
    });
  };

  onDragOver($event) {
    this.dragOver = true;
    this.dropped = false;
    $event.preventDefault();
    $event.stopPropagation();
  }

  filterAndAddFiles(files: File[]) {
    this.files = [];

    for (const file of files) {
      let allowed = false;
      for (const type of this.acceptedFiles) {
        if (file.name.toLowerCase().endsWith(type)) {
          allowed = true;
          break;
        }
      }
      if (allowed && (!this.maxFiles || this.maxFiles > this.files.length)) {
        this.files.push(file);
      }
    }
    this.generateThumbs();
    this.filesChange.emit(this.files);
    if (this.files.length > 0) {
      this.ready.emit(true);
    }
  }

  generateThumbs() {
    this.thumbs = [];
    for (let i = 0; i < this.files.length; i++) {
      if (i >= this.numberOfThumbs) {break;}
      const file = this.files[i];

      const reader = new FileReader();
      reader.onload = e => this.thumbs.push(this.sanitizer.bypassSecurityTrustResourceUrl(reader.result.toString()));

      reader.readAsDataURL(file);
    }
  }
}
