<div>
    <ion-searchbar inputmode=search #searchbar debounce=200 (ionChange)=setSearch($event) [value]=searchValue [placeholder]=placeholder [disabled]=disabled></ion-searchbar>
    <ng-container *ngIf="filteredSearchResults$ | async as results">
      <ion-list class="typeahead-list" style="margin-top: -12px;" [ngClass]="(loading$ | async) ? '' : 'end'">
        <ng-container [ngSwitch]=searchFor>
            <ng-container *ngSwitchCase="'galleries'">
                <ion-item *ngFor="let r of results" lines=none (click)=selectResult(r) [disabled]="isDuplicate(values, r.id)" button [detail]=false>
                    <ng-container [ngSwitch]=r.__t>
                        <ion-icon slot=start *ngSwitchCase="'CARD'"src="/assets/img/favicon.svg"></ion-icon>
                        <ion-icon slot=start *ngSwitchCase="'ATTENDEE'" name=person></ion-icon>
                        <ion-icon slot=start *ngSwitchDefault name=help-circle-outline></ion-icon>
                    </ng-container>
                    <div [innerHtml]="getDisplayString(r) | highlight: searchValue"></div>
                    </ion-item>
                    <ion-item *ngIf="!isDuplicate(results, searchValue) && allowNew" lines=none (click)=selectUnknown() [disabled]="isDuplicate(values, searchValue)" button [detail]=false>
                    New Subject: {{searchValue}}
                </ion-item>
            </ng-container>
            <ng-container *ngSwitchDefault>
                <ion-item *ngFor="let r of results" lines=none (click)=selectResult(r) button [detail]=false>
                    <div [innerHtml]="getDisplayString(r) | highlight: searchValue"></div>
                </ion-item>
            </ng-container>
        </ng-container>
      </ion-list>
    </ng-container>
    <ng-container>
      <ion-list *ngIf="(loading$ | async)" class="typeahead-list loader end" [ngStyle]="(filteredSearchResults$ | async) ? { 'margin-top': '-10px'} : { 'margin-top': '-12px'}">
          <app-loading size=small></app-loading>
      </ion-list>
      </ng-container>
  </div>
