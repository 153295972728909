import { Component, OnInit, HostListener, } from '@angular/core';
import { MediaUploadManagerService } from 'src/app/services/media-upload-manager/media-upload-manager.service';
import { UploadJob } from 'src/app/models/upload-job';
import { AlertService } from 'src/app/services/alert/alert.service';
import { BulkNotifyService } from 'src/app/services/bulk-notify/bulk-notify.service';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-job-manager',
  templateUrl: './job-manager.component.html',
  styleUrls: ['./job-manager.component.scss']
})
export class JobManagerComponent implements OnInit {

  public jobs: UploadJob[] = [];

  constructor(
    private mediaUpload: MediaUploadManagerService,
    private notifier: BulkNotifyService,
    private alerts: AlertService
  ) { }

  @HostListener('window:beforeunload', ['$event'])
  unloadHandler(event: Event) {
    if (this.jobs.length > 0) {
      event.returnValue = true;
    }
  }

  ngOnInit(): void {
    combineLatest([this.mediaUpload.onUpdatedJobs$, this.notifier.onUpdatedJobs$])
      .pipe(map((allJobs) => ([].concat(...allJobs)).sort((a, b) => a.start - b.start)))
      .subscribe((jobs: UploadJob[]) => {
      if (jobs) {
        // console.log(jobs);
        this.jobs = jobs;
      }
    });
  }

  allJobsComplete() {
    let rtn = true;
    for( const job of this.jobs ) {
      rtn = rtn && (job.items.length === job.processedItems.length + job.failedItems.length);
    }
    return rtn;
  }

  async jobActionButton(job: UploadJob) {
    if (job.items.length !== job.processedItems.length + job.failedItems.length) {
      const cancelJob = await this.alerts.negative(
        'Are you sure you want to cancel this job?',
        'Yes', job.cancelWarning, 'No, keep running!');
      if (!cancelJob) { return; }
    }
    switch (job.jobType) {
      case 'upload':
        this.mediaUpload.removeJob(job);
        break;
      case 'notify':
        this.notifier.removeJob(job);
        break;
    }
  }

}
