import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { merge, fromEvent, Observable, Observer } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ConnectionStateService {

  state$ = this.buildConnectionMonitor();

  constructor(private logger: NGXLogger) { }

  buildConnectionMonitor(): Observable<boolean> {
    return merge<boolean>(
      fromEvent(window, 'offline').pipe(tap(() => this.logger.info('Internet disconnected')), map(() => false)),
      fromEvent(window, 'online').pipe(tap(() => this.logger.info('Internet connected')), map(() => true)),
      new Observable((sub: Observer<boolean>) => {
        sub.next(navigator.onLine);
        sub.complete();
      }));
  }
}
