import { Input, Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { EnvService } from 'src/app/services/env/env.service';
import { ConsoleStateService } from 'src/app/services/console-state/consoleState.service';
import { INTEGRATION_DETAIL, INTEGRATION_SERVICE } from 'src/app/models/integration-service';
import { IdsService } from 'src/app/services/ids/ids.service';
import { ModuleService } from 'src/app/services/module/module.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-integration-new',
  templateUrl: './integration-new.html',
  styleUrls: ['./integration-new.scss']
})

export class NewIntegrationModalComponent implements OnInit {

  @Input() serviceOptions;
  @Input() existingIntegrations;
  @Input() memberId;
  newIntegration: any = {
    service: '',

  };
  stripeConnectUrl = '';
  externalIntegrationComplete = new Subject<boolean>();
  searchValue;

  public intService = INTEGRATION_SERVICE;

  public intDetail = INTEGRATION_DETAIL;

  constructor(
    public modalCtrl: ModalController,
    private envService: EnvService,
    public idsService: IdsService,
    public moduleService: ModuleService,
    private consoleState: ConsoleStateService) {
}


  ngOnInit(): void {
    this.serviceOptions = this.serviceOptions.filter((service) => !this.existingIntegrations.includes(service));
  }

  selectService(service) {
    this.newIntegration.service = service;
    if (this.intDetail[service].redirect) {
      this.consoleState.set(service, 'pending');
      this.consoleState.set(service + '_member', this.memberId);
      window.open(this.intDetail[service].redirect, '_blank');
      this.waitForExternalIntegration(service);
    }
  }

  setSearch($event) {
    this.searchValue = $event ? $event.detail.value : null;
  }

  leftHandler() {
    if (this.newIntegration.service) {
      this.newIntegration.service = null;
    } else {
      this.modalCtrl.dismiss();
    }
  }

  save() {
    this.modalCtrl.dismiss(this.newIntegration);
  }

  waitForExternalIntegration(service) {
    const sub = this.consoleState.watch(service).subscribe(() => {
        this.externalIntegrationComplete.next(true);
        sub.unsubscribe();
    });
  }
}


