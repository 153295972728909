import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, of, Subject, Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { EnvService } from '../services/env/env.service';
import { ToastService } from '../services/toast/toast.service';
import { catchError, switchMap } from 'rxjs/operators';
import { TableData } from '../models/table-data';


@Injectable()
export abstract class TableService<T> implements OnDestroy {

  public data$ = new BehaviorSubject<Array<T>>([]);

  public loading$ = new BehaviorSubject<boolean>(true);
  public total$ = new BehaviorSubject(0);
  public page = 0;
  public sort;
  public sortDirection = 'asc';
  public search;
  public event;
  public member;
  public url: string;
  public selected = [];

  public load$ = new Subject();

  private subs: Array<Subscription> = [];

  constructor(
    public http: HttpClient,
    public envService: EnvService,
    public toast: ToastService) {
    this.subs.push(this.load$.pipe(switchMap(() => this.getRows()), catchError((err) => {
      this.toast.error('Failed to load table');
      this.total$.next(0);
      this.loading$.next(false);
      this.data$.next([]);
      return of(err);
    })).subscribe(({ data, total }) => {
      this.total$.next(total);
      this.loading$.next(false);
      this.data$.next(data);
    }));
  }

  ngOnDestroy(): void {
    for (const sub of this.subs) {
      sub.unsubscribe();
    }
  }

  public load() {
    this.load$.next();
  }

  public setPage($event) {
    this.page = $event.offset;
    this.load();
  }

  public setSort($event) {
    this.page = 0;
    this.sort = $event.column.prop;
    this.sortDirection = $event.newValue;
    this.load();
  }

  public setEvent(event?) {
    this.page = 0;
    this.event = event ? event : null;
    this.load();
  }

  public setMember(member?) {
    this.page = 0;
    this.member = member ? member : null;
    this.load();
  }

  public onSearch(value) {
    this.page = 0;
    this.search = value;
    this.load();
  }

  public getHeight(row: any, index: number): number {
    if (row) {
      return (row.pics.length % 12) + 1 * 100;
    }
  }

  private getRows() {
    this.loading$.next(true);
    return this.http.get<TableData<T>>
      (this.envService.env.api + this.url + this.buildQueryParams());
  }

  private buildQueryParams(): string {
    const params = [];
    params.push('page=' + this.page);
    if (this.sort) {
      params.push('sort=' + this.sort);
      params.push('sortDirection=' + (this.sortDirection === 'desc' ? -1 : 1));
    }
    if (this.search) {
      params.push('search=' + this.search);
    }
    if (this.event) {
      params.push('event=' + this.event);
    }
    if (this.member) {
      params.push('member=' + this.member);
    }
    return '?' + params.join(';');
  }



}
