import { Component, HostListener, ViewChild, ViewContainerRef, AfterViewInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { EnvService } from './services/env/env.service';
import { slideInAnimation } from './animations';
import { RouterOutlet, Router, NavigationEnd } from '@angular/router';
import { enUS, enAU } from 'date-fns/locale';
import { DateFnsConfigurationService } from 'ngx-date-fns';
import { UserService } from './services/user/user.service';
import { CognitoService } from './services/cognito/cognito.service';
import { MenuController, PopoverController } from '@ionic/angular';
import { EditorService } from './services/editor/editor.service';
import { Location } from '@angular/common';
import { filter, pairwise } from 'rxjs/operators';
import { UrlObserverService } from './services/url-observer/urlObserver.service';
import { HttpPendingService } from './services/http-pending/http-pending.service';
// Analytics
import { Angulartics2GoogleGlobalSiteTag } from 'angulartics2/gst';
import { GAnalyticsService } from 'src/app/services/googleanalytics/g-analytics.service';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    slideInAnimation
    // animation triggers go here
  ]
})
export class AppComponent implements AfterViewInit {

  currentTab;

  showBack = false;
  backUrl = '/'

  title = 'console';
  user$ = this.userService.user$;

  @ViewChild('editorContainer', { read: ViewContainerRef }) container: ViewContainerRef;

  constructor(
    private titleService: Title,
    private userService: UserService,
    private envService: EnvService,
    private editorService: EditorService,
    private cognitoService: CognitoService,
    private menuCtrl: MenuController,
    private popoverController: PopoverController,
    urlObserver: UrlObserverService,
    private location: Location,
    private router: Router,
    private angulartics: Angulartics2GoogleGlobalSiteTag,
    private ganalyticsService: GAnalyticsService,
    private config: DateFnsConfigurationService) {
      const windowTitle = 'Elementice Console' + envService.env.titleSuffix;
      this.titleService.setTitle( windowTitle );
      console.log(this.envService.env.version);
      if (window.navigator.language === 'en-AU') {
        this.config.setLocale(enAU);
        console.log('Locale set to: enAU');
      } else {
        this.config.setLocale(enUS);
        console.log('Locale set to: enUS');
      }
      this.router.events.pipe(filter((event) => (event instanceof NavigationEnd)), pairwise()).subscribe(([e1, e2]) => {
        this.showBack = (e2 as NavigationEnd).url.split('/').length > 2
        this.backUrl = (e1 as NavigationEnd).url;
      })

  }

  /** listen for key events to switch images */
  //@HostListener('body:click', ['$event'])
  //logEvent(event: any) {
  //  this.ganalyticsService.eventTagger(event);
  //}

  ngAfterViewInit() {
    this.editorService.container = this.container;
  }

  goBack() {
    this.location.back()
  }

  tabChangeHandler(event) {
    this.currentTab = event.tab;

  }

  getTab(tab): string {
    return tab === this.currentTab ? 'selected-tab' : '';
  }

  // firefox
  @HostListener('onmousewheel', ['$event']) scrollFF(event: MouseEvent) {
    this.dismissPopover();
  }
  // chrome
  @HostListener('mousewheel', ['$event']) scrollCh(event: MouseEvent) {
    this.dismissPopover();
  }

  dismissPopover() {
    this.popoverController.getTop().then((popover) => {
      if (popover) {
        this.popoverController.dismiss().catch(() => {});
      }
    }).catch(() => {});
  }

  logout() {
    this.menuCtrl.close();
    this.cognitoService.logout();
    this.router.navigate(['/login']);
  }

  navigate(page, id?) {
    this.menuCtrl.close();
    if (id) {
      this.router.navigate([page, id]);
    } else {
      this.router.navigate([page]);
    }

  }


  getUserRole(user) {
    if (user.isAdmin) {
      return 'Admin';
    } else if (user.isManager) {
      return 'Manager';
    } else {
      return '';
    }
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData;
  }
}
