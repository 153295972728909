import { Input, Component, ViewChild, OnInit, ElementRef } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { IdsService } from 'src/app/services/ids/ids.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { GalleryDetail } from 'src/app/models/gallery-detail';
import { BehaviorSubject } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';

import { MediaUploadManagerService } from 'src/app/services/media-upload-manager/media-upload-manager.service';
import { UploadJob } from 'src/app/models/upload-job';

@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.html',
  styleUrls: ['./image-upload.scss']
})

export class ImageUploadModalComponent implements OnInit {
  @Input() memberId: number;
  @Input() eventId: string;
  @Input() uploadName: string;
  @Input() id: GalleryDetail;

  files: File[] = [];

  imageMetadata: any = {
    timestamp: (new Date()).getTime(),
    apiVersion: 1,
    orientation:0,
    uploadMethod: 'manual',
    deviceId: 0,
    latLong: null,
    eventId: '',
    browserTimezoneOffset: new Date().getTimezoneOffset()
  };

  ready = false;
  uploading = false;
  loading = new BehaviorSubject(false);
  subjects: GalleryDetail[] = [];

  idForm: FormGroup = new FormGroup({
    id: new FormControl('', Validators.required)
 });

  constructor(
    private modalCtrl: ModalController,
    private toast: ToastService,
    public idsService: IdsService,
    private uploadManagerService: MediaUploadManagerService,
    ) {

  }

  ngOnInit(): void {
    if (this.id) {
      this.subjects = this.subjects.concat([this.id]);
    }
  }

  trackByFn(index: any, item: any) {
    return index;
 }

  async upload() {
    this.imageMetadata.eventId = this.eventId;
    this.imageMetadata.subjects = this.subjects;

      for (let sub of this.imageMetadata.subjects) {
        if (!sub.new) {
          sub = {_id: sub._id};
        }
      }

    const uj: UploadJob = {
      jobName: this.uploadName + this.getSubjectsString(),
      items: this.files,
      metadata: this.imageMetadata,
      processedItems: [],
      member: this.memberId,
      failedItems: [],
      cancelled: false,
      started: new Date()
    };
    this.uploadManagerService.addJob(uj);
    this.close();
  }

  close() {
    this.modalCtrl.dismiss();
  }

  removeId(subject: GalleryDetail) {
    this.subjects = this.subjects.filter((newId) => newId.id !== subject.id);
  }

  addId(subject: GalleryDetail) {
      this.subjects.push(subject);
  }

  addNew(val: string) {
    this.subjects.push({
      id: val,
      __t: 'OTHER',
      member: this.memberId,
      active: false,
      new: true
    });
  }

  async successToast() {
    this.toast.success('Reassign successful');
  }

  async failureToast() {
    this.toast.warning('Reassign failed');
  }

  getSubjectsString() {
    if (this.subjects.length > 0) {
      let str = ` (${this.subjects[0].id}`
      if (this.subjects.length > 1) {
        str += ` + ${this.subjects.length - 1} more`
      }
      return str + ')'
    } else return ''
  }

}
