
  <app-modal-header title="New Integration"></app-modal-header>

  <ion-content>

    <form #newIntegrationForm="ngForm">
      <ion-grid >
        <ion-row [hidden]="newIntegration.service">
          <ion-col size=12>
            <ion-searchbar inputmode=search #searchbar debounce=0 (ionChange)=setSearch($event) [value]=searchValue></ion-searchbar>
          </ion-col>
          <ion-col size=12 size-md=6 *ngFor="let service of serviceOptions | search: null: searchValue" >
            <ion-card button (click)="selectService(service)" [disabled]="!(moduleService.canUse(service, memberId) | async)">
              <div class=logo-container>
                <img class=logo src="assets/img/integration/banner/{{service}}.png" [alt]=intDetail[service].name/>
              </div>
              <ion-card-header>
                <ion-card-subtitle>{{intDetail[service].name}}</ion-card-subtitle>
              </ion-card-header>
              <ion-card-content class=text-height>
                {{intDetail[service].desc}}
              </ion-card-content>
            </ion-card>
          </ion-col>
          <ion-col *ngIf="serviceOptions.length === 0">
            <h1 class="text-center text-muted">No Integrations Available</h1>
          </ion-col>
        </ion-row>
      <ion-row *ngIf="newIntegration.service">

        <ion-col size=12>
            <img class=logo src="assets/img/integration/banner/{{newIntegration.service}}.png" [alt]=intDetail[newIntegration.service].name/>
        </ion-col>
        <ion-col size=12 class=ion-text-center>
          <h4>{{intDetail[newIntegration.service].name}}</h4>
        </ion-col>
      </ion-row>

      <ion-row>
        <ng-container *ngIf="newIntegration.service === intService.FOTOMERCHANT">
          <ion-col size=12>
            <ion-item lines="none">
              <ion-label >Token</ion-label>
              <ion-input class="p-l-10 form-control" type="text" required name="service"
                  [(ngModel)]="newIntegration.token"></ion-input>
            </ion-item>
          </ion-col>
        </ng-container>
      </ion-row>

      <ion-row >
        <ng-container *ngIf="newIntegration.service === intService.GFP">
          <ion-col size=12>
            <ion-item lines="none">
              <ion-label >Token</ion-label>
              <ion-input class="p-l-10 form-control" type="text" required name="service"
                  [(ngModel)]="newIntegration.token"></ion-input>
            </ion-item>
          </ion-col>
        </ng-container>
      </ion-row>

    <ion-row *ngIf="newIntegration.service === intService.EI_MESSAGING">
      <ion-col size=12 class=ion-text-center>
          <p>This integration gives the option to send SMS notifications to your attendees.</p>
          <p>Messages will be delivered from 'ELEMENTICE' and include a link to the attendee's gallery.</p>
          <p>You can configure the message text, and frequency for each Event you add this Integration to.</p>
          <p>Messages are always sent after other Integrations are completed.</p>
      </ion-col>
    </ion-row>

    <ion-row *ngIf="newIntegration.service === intService.FACIAL_REC">
      <ion-col size=12 class=ion-text-center>
          <p>This integration allows use of facial information to sort images into galleries.</p>
          <p>Used in combination with Event registration, and EI Messaging, images can be delivered to Event guests without scanning anything.</p>
          <p>Facial Recognition is always completed before those which depend on subjects.</p>
      </ion-col>
    </ion-row>

    <ion-row *ngIf="newIntegration.service === intService.ENHANCEMENT">
      <ion-col size=12 class=ion-text-center>
        <p>This integration allows images to be automatically corrected before they are seen by customers.</p>
      </ion-col>
    </ion-row>

    <ion-row *ngIf="newIntegration.service === intService.OCR">
      <ion-col size=12 class=ion-text-center>
        <p>This integration allows text in images to be used to add subjects.</p>
      </ion-col>
    </ion-row>

    <ng-container *ngIf="newIntegration.service && intDetail[newIntegration.service].redirect">
      <ion-row *ngIf="!(externalIntegrationComplete | async)">
        <ion-col>
          <app-loading loadingText="Waiting for authorization..."></app-loading>
        </ion-col>
      </ion-row>
  
      <ion-row *ngIf="externalIntegrationComplete | async">
        <ion-col class=ion-text-center>
            You may close this web page.
        </ion-col>
      </ion-row>
    </ng-container>

  </ion-grid>
  </form>
</ion-content>

  
<app-modal-footer
(left)=leftHandler()
[leftColor]="newIntegration.service ? 'primary' : 'danger'"
[leftLabel]="newIntegration.service ? 'Back' : 'Close'"
[leftIcon]="newIntegration.service ? 'chevron-back-outline' : 'close-outline'"
[showRight]="newIntegration.service && !intDetail[newIntegration.service].redirect"
(right)=save()
[rightDisabled]="!newIntegrationForm.form.valid || !newIntegration.service">
</app-modal-footer>