import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvService } from '../env/env.service';
import { ModalController } from '@ionic/angular';
import { ToastService } from '../toast/toast.service';
import { AlertService } from '../alert/alert.service';
import { AttendeeEditModalComponent } from 'src/app/shared/modals/attendee-edit/attendeeEdit.component';
import { AttendeeShowBarcodeModalComponent } from 'src/app/shared/modals/attendee-show-barcode/attendeeShowBarcode.component';
import { AttendeesReviewModalComponent } from 'src/app/shared/modals/attendees-review/attendeesReview.component';
import { IdsService } from '../ids/ids.service';
import { ImageUploadModalComponent } from 'src/app/shared/modals/image-upload/imageUpload.component';
import { saveAs } from 'file-saver';
import { Gallery } from 'src/app/models/gallery';

@Injectable({
  providedIn: 'root'
})
export class AttendeeService {

  exporting = false;


  constructor(
    private http: HttpClient,
    private envService: EnvService,
    private toast: ToastService,
    private alert: AlertService,
    private idsService: IdsService,
    private modalCtrl: ModalController) { }

  async editAttendee(attendeeGallery: Gallery, eventId?: string) {
    if (!eventId) {
      eventId = attendeeGallery.images[0].event;
    }
    const modal = await this.modalCtrl.create({
      component: AttendeeEditModalComponent,
      componentProps: {
        identity: attendeeGallery.identity,
        gallery: attendeeGallery,
        eventId
      }
    });
    await modal.present();
    await modal.onWillDismiss();
  }

  async showAttendeeBarcode(attendee, eventId: string) {
    // if (!eventId) {
    //   if (attendee.publicCodes.length > 1) {
    //     eventId = await this.chooseEvent(attendee, 'Show');
    //   } else {
    //     eventId = attendee.publicCodes[0].eventId;
    //   }
    // }
    const modal = await this.modalCtrl.create({
      component: AttendeeShowBarcodeModalComponent,
      componentProps: {
        eventId,
        attendee
      }
    });
    await modal.present();
  }

  async readFile(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = res => {
        resolve(res.target.result);
      };
      reader.onerror = err => reject(err);

      reader.readAsText(file);
    });
  }

  async uploadAttendees(eventId: string, $event) {

    const resp = await fetch(`${this.envService.env.ipStackCheck}`)
      .then(res => res.json())
      .catch(() => ({ country_code: this.envService.env.DEFAULT_PHONE_COUNTRY }));
    const file = $event.target.files[0];
    const fileData = await this.readFile(file);
    // console.log(fileData);
    const result = await this.http.post(
      `${this.envService.env.api}events/csv/attendees/${eventId}?country=${resp.country_code}`,
      fileData).toPromise();
    const reviewModal = await this.modalCtrl.create({
      component: AttendeesReviewModalComponent,
      componentProps: {
        results: result,
        eventId
      }
    });
    await reviewModal.present();
    await reviewModal.onDidDismiss();
  }

  downloadTemplate() {
    saveAs('assets/elementice-attendees-template.csv', 'elementice-attendees-template.csv');
  }

  async resendNotification(attendeeId: string, eventId: string) {
    // console.log(attendee)
    return this.http.post<{ result: { detail: string, segments: string } }>(this.envService.env.api + `integrations/notify/${eventId}/${attendeeId}`, {}).toPromise();

  }

  openInMyPics(attendee) {
    window.open(this.envService.env.galleryUrl + '/i/' + attendee.publicCode, '_blank');
  }

  async getAll(eventId): Promise<Array<{ id: string, _id: string }>> {
    return this.http.get<Array<{ id: string, _id: string }>>
      (this.envService.env.api + `galleries/ids?event=${eventId};type=ATTENDEE;limit=10000`).toPromise();
  }

  async upload(attendee: Gallery, eventId) {
    const modal = await this.modalCtrl.create({
      component: ImageUploadModalComponent,
      componentProps: {
        uploadName: 'Attendee: ' + attendee.id,
        memberId: attendee.member,
        eventId,
        id: { id: attendee.id, __t: attendee.__t, _id: attendee._id }
      }
    });

    await modal.present();
    await modal.onWillDismiss();
  }

  async chooseEvent(codes, confirmText = 'Select') {
    const promises = codes.map((ev) => new Promise((resolve) => {
      this.idsService.getEventName(ev.eventId).subscribe((n) => {
        resolve({
          value: ev.eventId,
          label: n
        });
      });
    }));
    const options = await Promise.all(promises);
    return this.alert.select('Choose Event', confirmText, options);
  }

  async export(eventId) {
    this.exporting = true;
    this.http.get(this.envService.env.api + 'events/csv/attendees/' + eventId, { responseType: 'blob' })
      .subscribe((result) => {
        saveAs(result, 'attendee-export.csv');
        this.exporting = false;
      }, (err) => {
        this.exporting = false;
        this.toast.error(err);
      });
  }

  async remove(attendee) {
    const clear = await this.alert.negative(
      'Remove attendee?',
      'Remove',
      'Attendee will be de-identified and moved to \'Other IDs\'.');
    if (!clear) { return; }
    return this.http.delete(this.envService.env.api + 'galleries/attendees/' + attendee._id).toPromise().then((result) => {
      // console.log(result)
    }, () => {
      this.toast.error('Failed to remove attendee');
    });
  }

  async clear(eventId) {
    const clear = await this.alert.negative(
      'Clear all attendees?',
      'Clear',
      'Attendees will still exist, but be detached from this event.');
    if (!clear) { return; }
    return this.http.delete(this.envService.env.api + 'galleries/attendees/clear/' + eventId).toPromise().then((result) => {
      // console.log(result)
    }, () => {
      this.toast.error('Failed to clear attendees');
    });
  }

}
