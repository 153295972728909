import { Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { NGXLogger } from 'ngx-logger';

interface Message {
  type: string;
  payload: any;
}

type MessageCallback = (payload: any) => void;

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  constructor(private logger: NGXLogger) { }

  private handler = new Subject<Message>();

  broadcast(type: string, payload?: any) {
    if (type === 'newImage') {
      this.logger.info('Broadcasting:', type, payload);
    }

    this.handler.next({ type, payload });
  }

  subscribe(type: string, callback: MessageCallback): Subscription {
    return this.handler.pipe(filter(message => message.type === type), map(message => message.payload)).subscribe(callback);
  }
}
