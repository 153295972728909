import { environment } from '../../environments/environment';

export enum INTEGRATION_SERVICE {
    FOTOMERCHANT = 'FOTOMERCHANT',
    DROPBOX = 'DROPBOX',
    FACIAL_REC = 'FACIAL_REC',
    EI_MESSAGING = 'EI_MESSAGING',
    STRIPE = 'STRIPE',
    ENHANCEMENT = 'ENHANCEMENT',
    OCR = 'OCR',
    GFP = 'GFP',
}

export const INTEGRATION_DETAIL = {
    [INTEGRATION_SERVICE.FOTOMERCHANT] : {
      name: 'Fotomerchant',
      desc: 'Send sorted images to Fotomerchant to use their advanced e-commerce platform',
    },
    [INTEGRATION_SERVICE.DROPBOX] : {
      name: 'Dropbox',
      desc: 'Upload images to Dropbox in your preferred file structure',
      redirect: environment.dropboxConnectUrl + environment.redirectUri
    },
    [INTEGRATION_SERVICE.STRIPE] : {
      name: 'Stripe',
      desc: 'Connect to Stripe and sell digitals through MyPics',
      redirect : environment.stripeConnectUrl
    },
    [INTEGRATION_SERVICE.EI_MESSAGING] : {
      name: 'Elementice Messaging',
      desc: 'Send SMS messages to your attendees when their images arrrive'
    },
    [INTEGRATION_SERVICE.FACIAL_REC] : {
      name: 'Facial Recognition',
      desc: 'Use faces to sort images and forget barcodes!',
    },
    [INTEGRATION_SERVICE.ENHANCEMENT] : {
      name: 'Auto Image Enhancement (Beta)',
      desc: 'Automatically enhance & correct images using cutting edge AI',
    },
    [INTEGRATION_SERVICE.OCR] : {
      name: 'Optical Character Recognition',
      desc: 'Find text in images and use it for gallery association',
    },
    [INTEGRATION_SERVICE.GFP] : {
      name: 'GFP',
      desc: 'Send sorted images to GES e-commerce.',
    }
  };
