import { Component, HostListener, Input, OnDestroy, ViewChild } from '@angular/core';
import { ModalController, IonSearchbar } from '@ionic/angular';
import { combineLatest, Observable, Subject, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-search-select-modal',
  templateUrl: './search-select-modal.component.html',
  styleUrls: ['./search-select-modal.component.scss']
})
export class SearchSelectModalComponent implements OnDestroy {

  @Input() list: Observable<any[]>;

  @ViewChild('searchbar') searchbar: IonSearchbar;

  searchValue$ = new Subject<string>();

  item;

  filteredList$: Observable<any[]>;

  keyBoardEvents = new Subject<KeyboardEvent>();
  keyBoardEventsSub: Subscription;

  constructor(private modalCtrl: ModalController) { }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    this.keyBoardEvents.next(event);
  }

  ngOnDestroy(): void {
    this.keyBoardEventsSub.unsubscribe();
  }

  ionViewDidEnter() {
    setTimeout(async () => {
      await this.searchbar.setFocus();
    });

    this.filteredList$ = combineLatest([this.list, this.searchValue$.pipe(startWith(null))])
      .pipe(map(([ls, search]) => {
        if (search) {
          const filtered = ls.filter(l => l.value.toUpperCase().includes(search.toUpperCase()));
          if (this.item && filtered.findIndex((i) => i.key === this.item.key) === -1) {
            this.item = null;
          }
          return filtered;
         }
        else { return ls; }
        }));

    this.keyBoardEventsSub = combineLatest([this.keyBoardEvents, this.filteredList$]).subscribe(([event, ls]) => {
      if (event.code === 'ArrowUp') {
        if (ls && ls.length > 0) {
          if (!this.item) {
            this.item = ls[0];
          } else {
            const current = ls.findIndex((l) => l.key === this.item.key);
            if (current > 0) {
              this.item = ls[current - 1];
            }
          }
        }
      }
      if (event.code === 'ArrowDown') {
        if (ls && ls.length > 0) {
          if (!this.item) {
            this.item = ls[0];
          } else {
            const current = ls.findIndex((l) => l.key === this.item.key);
            if (current > -1 && current < ls.length - 2) {
              this.item = ls[current + 1];
            }
          }
        }
      }
      if (event.code === 'Enter') {
        if (this.item) {
          this.confirm();
        }
      }
    });
  }



  setSearch($event) {
    this.searchValue$.next($event ? $event.detail.value : null);
  }

  select(option) {
    this.item = option;
  }

  cancel() {
    this.modalCtrl.dismiss();
  }

  confirm() {
    this.modalCtrl.dismiss(this.item);
  }

}
