import { EmbeddedViewRef, AfterViewInit } from '@angular/core';
import { ChangeDetectorRef, Directive, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/services/user/user.service';

@Directive({
  selector: '[isAdmin]'
})
export class AdminDirective implements OnDestroy, AfterViewInit {

  @Input() isAdmin = true;

  // set true to limit to elementice members only
  @Input() isAdminEiOnly = false;

  adminSub: Subscription;

  view: EmbeddedViewRef<any>;

  constructor(
    public userService: UserService,
    private templateRef: TemplateRef<any>,
    private changes: ChangeDetectorRef,
    private viewContainer: ViewContainerRef) {


  }

  ngAfterViewInit() {
    this.adminSub = this.userService.user$.pipe().subscribe((user) => {
      if (this.canShow(user)) {
        if (this.view === undefined) {
          this.view = this.viewContainer.createEmbeddedView(this.templateRef);
        }
      } else {
        this.viewContainer.clear();
      }
      this.changes.markForCheck();
    });
  }

  ngOnDestroy(): void {
    this.adminSub.unsubscribe();
  }

  canShow(user) {
    if (!this.isAdminEiOnly) {
      return user.isAdmin === this.isAdmin;
    } else {
      return user.isAdmin && user.memberId === 2;
    }
  }

}
