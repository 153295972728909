import { Component, Input } from '@angular/core';

import { EnvService } from 'src/app/services/env/env.service';

import { HttpClient } from '@angular/common/http';

import { ModalController } from '@ionic/angular';
import { AlertService } from 'src/app/services/alert/alert.service';

@Component({
  selector: 'app-attendees-review',
  templateUrl: './attendees-review.html',
  styleUrls: ['./attendees-review.scss']
})

export class AttendeesReviewModalComponent {

  @Input() results;
  @Input() eventId;

  constructor(
    private envService: EnvService,
    private http: HttpClient,
    public modalCtrl: ModalController,
    private alert: AlertService) {

  }

  useNew(conflict) {
    this.http.post(this.envService.env.api + 'events/' + this.eventId + '/attendee/new', [conflict.incoming])
    .toPromise().then((response) => {
      const index = this.results.conflicts.indexOf(conflict);
      this.results.conflicts.splice(index, 1);
      this.results.importedNum++;
    });
  }

  async useAllNew(conflicts) {
    const create = await this.alert.affirmative('Create all new attendees?', 'Yes');
    if (!create) {return;}
    this.http.post(this.envService.env.api + 'events/' + this.eventId + '/attendee/new', conflicts.map((c) => c.incoming))
    .toPromise().then((response) => {
      conflicts.forEach((conflict) => {
        const index = this.results.conflicts.indexOf(conflict);
        this.results.conflicts.splice(index, 1);
        this.results.importedNum++;
      });
    });
  }

  useExisting(conflict, existingConflict) {
    const manualAddObj = {
      id: conflict.incoming.id,
      identity: existingConflict._id
    };

    this.http.post(this.envService.env.api + 'events/' + this.eventId + '/attendee/existing', [manualAddObj])
    .toPromise().then((response) => {
      const index = this.results.conflicts.indexOf(conflict);
      this.results.conflicts.splice(index, 1);
      this.results.importedNum++;
    });
  }

  resolved() {
    return this.results.conflicts.length === 0;
  }

  close() {
    this.modalCtrl.dismiss();
  }
}

