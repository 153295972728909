<app-modal-header
title='Attendee Details'
></app-modal-header>


    <ion-content>
      <!-- this area must have inline styles so they are maintained through to a printable page -->
      <div id=printArea class=print-area style="text-align: center;">
        <div style="margin-bottom: 10px; font-size: 8px;">
          {{ getDateString() }}
        </div>
        <div style="margin-bottom: 10px; font-weight: bold">
         {{ idsService.getEventName(eventId) | async }}
        </div>
        <div style="margin-bottom: 10px;">
         {{ attendee.identity.firstName}} {{ attendee.identity.lastName}}
        </div>
        <div style="margin-bottom: 10px; font-style: italic;">
          {{ attendee.identity.company}}
         </div>
        <div style="margin-bottom: 10px;">
          {{ attendee.identity.mobileNumber }}
        </div>
        <div style="margin-bottom: 10px;">
          {{ attendee.identity.emailAddress }}
        </div>
        <div style="margin-bottom: 10px;">
          {{ attendee.identity.notes }}
        </div>
        <div style="margin-bottom: 10px;" *ngFor="let text of freeText">
          {{ text }}
        </div>
        <div class=row>
          <div class=col>
            <qr-code [value]="attendee.id" size="100" errorCorrectionLevel="M"></qr-code>
          </div>
        </div>
      </div>
      <ion-input (keyup.enter)=addField() [(ngModel)]="newText" autocorrect="on" [autofocus]=true placeholder="Add text to this print out..."></ion-input>
    </ion-content>


  <app-modal-footer
  (left)=close()
  (right)=printDiv()
  (action)=addField()
  [actionDisabled]=!newText
  [showAction]=true
  actionLabel="Add Text"
  rightLabel=Print
  rightColor=success
  rightIcon=print-outline
  ></app-modal-footer>