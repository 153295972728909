import { trigger, transition, style, query, animateChild, animate, group } from '@angular/animations';

export const slideInAnimation =
  trigger('routeAnimations', [
    transition('* <=> *', [
      style({ position: 'relative' }),
      query(':enter', [
        style({ opacity: '0%' })
      ], { optional: true }),
      // query(':leave', animateChild(), { optional: true }),
      group([
        // query(':leave', [
        //   style({ opacity: '0%'})
        // ], { optional: true }),
        query(':enter', [
          animate('200ms ease-in', style({ opacity: '100%' }))
        ], { optional: true })
      ]),
      query(':enter', animateChild(), { optional: true }),
    ])

  ]);

export const expandInOutAnimation =
  trigger('expandInOutAnimation', [
    transition(':enter', [
      style({ 'max-height': '0px'}),  // initial
      animate('0.2s',
        style({ 'max-height': '20px'}))  // final
    ]),
    transition(':leave', [
      style({ 'max-height': '20px'}),  // initial
      animate('0.2s',
        style({ 'max-height': '0px'}))  // final
    ])
  ]);
