import { NgModuleFactory, Type } from '@angular/core';

// This will create a dedicated JS bundle for lazy module
export const lazyWidgets: { path: string; loadChildren: () => Promise<NgModuleFactory<any> | Type<any>> }[] = [
  {
    path: 'editor',
    loadChildren: () => import('src/app/editor/editor.module').then(m => m.EditorModule)
  }
];

// This function will work as a factory for injecting lazy widget array in the main module
export const lazyArrayToObj = () => {
  const result = {};
  for (const w of lazyWidgets) {
    result[w.path] = w.loadChildren;
  }
  return result;
};
