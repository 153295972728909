
<app-modal-header
[title]=title
[info]="index + 1 + '/' + templates?.length"
></app-modal-header>

<ion-content style="--overflow: hidden;">

    <ion-slides
        [options]=sliderOpts
        (ionSlideDidChange)=slideHandler($event)
        #slider>
            <ion-slide *ngFor="let template of templates" (keydown.Tab)="$event.preventDefault();false;">
                <ng-container *ngTemplateOutlet="template.template"></ng-container>
            </ion-slide>
    </ion-slides>

</ion-content>

<app-modal-footer
    (left)=leftHandler()
    [leftLabel]="isStart ? 'Close' : 'Back'"
    [leftColor]="isStart ? 'danger' : 'primary'"
    [leftIcon]="isStart ? 'close-outline' : 'chevron-back-outline'"
    (right)=rightHandler()
    [rightLabel]="isEnd ? 'Submit' : 'Next'"
    [rightColor]="isEnd ? 'success' : 'primary'"
    [rightDisabled]="!valid || submitted"
    [rightIcon]="isEnd ? 'checkmark-outline' : 'chevron-forward-outline'"
    (action)="currentTemplate?.action?.func()"
    [showAction]="currentTemplate?.action"
    [actionLabel]="currentTemplate?.action?.label"
    [actionDisabled]="currentTemplate?.action?.disabled">
</app-modal-footer>
