// Jonathon Martin, Elementice, October 2019
import { Injectable } from '@angular/core';
import {LocalStorageService } from 'ngx-webstorage';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class ConsoleStateService {


  constructor(private storage: LocalStorageService, private logger: NGXLogger) {
  }


  ///////////////////// FUNCTIONS /////////////////////////
  public get(key): any {
    const retrievedState = this.storage.retrieve(key);
    this.logger.info('Retrieved from storage:', key, retrievedState);
    return retrievedState;
  }

  public set(key, value): void {
    this.storage.store(key, value);
  }

  public watch(key): Observable<any> {
    return this.storage.observe(key);
  }

  public delete(key) {
    return this.storage.clear(key);
  }

}


