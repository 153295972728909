import { Injectable, ViewContainerRef } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { LazyLoaderService } from '../lazy-loader/lazy-loader.service';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { LoadingService } from '../loading/loading.service';
import { EnvService } from '../env/env.service';
import { ToastService } from '../toast/toast.service';
import { EIMedia } from 'src/app/models/media';

@Injectable({
  providedIn: 'root'
})
export class EditorService {

  container: ViewContainerRef;

  image$ = new BehaviorSubject<any>(null);
  closed$ = new Subject<boolean>();
  editSubmitted$ = new Subject<boolean>();

  constructor(
    private lazyLoaderService: LazyLoaderService,
    private loadingService: LoadingService,
    private toast: ToastService,
    private envService: EnvService,
    private http: HttpClient) { }

  async launch() {
    if (this.container) {
      return this.lazyLoaderService.load('editor', this.container);
    } else {
      throw Error('Editor needs a container!');
    }
  }

  async imageInfo(): Promise<{url: string; image: EIMedia}> {
    this.loadingService.present('Loading full resolution image...');
    const image = this.image$.getValue();
    return this.http.get(image.hiresUrl, {
      headers: { 'x-access-token': 'true' },
      responseType: 'blob'}).pipe(map((result) => {
        this.loadingService.dismiss();
      return { url: URL.createObjectURL(result), image };
    })).toPromise();
  }

  async submitEdit(image: EIMedia, imageData: string) {
    const blob = await fetch(imageData).then(r => r.blob());
    this.loadingService.present('Uploading edited image...');
      const response = await this.http.post<{url: string}>(this.envService.env.api + 'images/get-presigned-url',
        {
          uploadType: 'image',
          key: image.original.filename,
          contentType: 'image/jpeg',
          reuseMetadata: true,
          metadata: {'x-amz-meta-ei-publiccode': image.publicCode}}).toPromise();
    this.http.put<any>(response.url, blob, { headers: {
      'Content-Type': blob.type,
      'Content-Length': `${blob.size}`,
      'x-access-token': 'true'
    }}).subscribe(() => {
        this.toast.success('Edit uploaded!');
        this.loadingService.dismiss();
        this.editSubmitted$.next(true);
    }, () => {
      this.toast.error('Edit upload failed...');
      this.loadingService.dismiss();
    });

  }
}
