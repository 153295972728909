<ion-header>
    <ion-toolbar>
        <ion-grid>
            <ion-row>
                <ion-col>
                    <h4 class=title>{{title}}</h4>
                    <h4 class=pager *ngIf="pagination">
                        {{info}}
                    </h4>
                </ion-col>
            </ion-row>
            <ion-row *ngIf=instructions>
                <ion-col size=12>
                    <p>
                        <small>{{instructions}}<br></small>
                    </p>
                </ion-col>
            </ion-row>
        </ion-grid>
    </ion-toolbar>
</ion-header>
