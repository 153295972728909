<div *ngIf="displayMode === 'search'" class=select-button (click)=choose($event) [ngClass]="{active: value, inactive: !value}">
    <div>
        <i class="zmdi zmdi-sort-amount-desc m-r-5"></i> {{displayValue || placeholder}}
    </div>
    <ion-button *ngIf="value && !stuck" fill=clear (click)=clear();$event.stopPropagation()>
        <ion-icon slot="icon-only" size=small color=dark name=close-circle></ion-icon>
    </ion-button>
</div>

<div *ngIf="displayMode === 'setting'" class="form-group col-md-6 fake-click-edit">
    <label class="col-form-label">{{settingLabel}}</label>
    <div class="col-12">
        <p class="form-control-static m-b-0">
        <a (click)=choose($event)>
            {{displayValue || placeholder}}
        </a>
        </p>
    </div>
</div>
