import { Component, Input, AfterViewInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FormBuilder, FormGroup } from '@angular/forms';
import { combineLatest, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AlertService } from 'src/app/services/alert/alert.service';

@Component({
  selector: 'app-integration-config-dropbox',
  templateUrl: './integration-config-dropbox.component.html',
  styleUrls: ['./integration-config-dropbox.component.scss']
})
export class DropboxConfigComponent implements AfterViewInit {

  @Input() event;
  @Input() config;
  @Input() getNewImageName;

  didShowWarning = false;

  exampleImageName$;

  now = Date.now().toString();

  newDropboxConfigForm: FormGroup;

  sortOptions = ['gallery', 'timestone', 'none'];

  controlsSub: Subscription;

  constructor(
    public modalCtrl: ModalController,
    private alert: AlertService,
    private formBuilder: FormBuilder) {
      this.newDropboxConfigForm = this.formBuilder.group({
        sortBy: [this.sortOptions[0]],
        folderName: [''],
        imageName: this.formBuilder.group({
          event: [true],
          device: [true],
          timestamp: [true],
          attendee: [false],
          unsafe: [false]
        }),

      });

      this.exampleImageName$ = combineLatest([
        this.newDropboxConfigForm.controls.imageName.valueChanges.pipe(startWith(this.newDropboxConfigForm.controls.imageName.value)),
        this.newDropboxConfigForm.controls.sortBy.valueChanges.pipe(startWith('gallery')),
      ]).pipe(map((value) => {
        const config = this.newDropboxConfigForm.value;
        config.imageName = value[0];
        config.sortBy = value[1];
        return this.getNewImageName(this.event.name, config);
      }));

      this.newDropboxConfigForm.controls.imageName.get('unsafe').valueChanges.subscribe((val) => {
        if (val && !this.didShowWarning) {
          this.didShowWarning = true;
          this.alert.info('UNSAFE NAMING',
          'Unsafe naming dramatically shortens name length, but makes it harder to tie an image to the ELEMENTICE system.' +
          'Only use this if you know what you\'re doing.');
        }
      });
    }

    ngAfterViewInit() {
      setTimeout(() => {
        if (this.config) {
          this.newDropboxConfigForm.setValue(
            {sortBy: this.config.sortBy, imageName: this.config.imageName, folderName: this.config.folderName}, {emitEvent: true});
        } else {
          this.newDropboxConfigForm.controls.folderName.setValue(this.event.name.split(' ').join('_'));
        }
      }, 0);
    }

  save() {
    this.modalCtrl.dismiss(this.newDropboxConfigForm.value);
  }

  close() {
    this.modalCtrl.dismiss();
  }

}
