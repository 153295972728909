import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { NGXLogger, NgxLoggerLevel } from 'ngx-logger';

@Injectable({
  providedIn: 'root'
})
/**
 * Provides stable access to env variables for components
 */
export class EnvService {

  env = environment;

  constructor(logger: NGXLogger) {
    if (!this.env.production) {
      logger.updateConfig({ level: NgxLoggerLevel.DEBUG });
    }
   }
}
