<ion-app>
    <app-job-manager></app-job-manager>
    <app-global-spinner class=pending></app-global-spinner>
    <ion-tabs (ionTabsWillChange)=tabChangeHandler($event) id="main" #tabs>
        <ion-tab-bar slot="top">
            <ion-button
                (click)=goBack()
                class=back
                fill=clear
                *ngIf=showBack>
                <ion-icon name=chevron-back-outline></ion-icon>
            </ion-button>

            <ion-tab-button class=logo-icon (click)="navigate('/')">
                <ion-icon src="assets/img/logo.svg"></ion-icon>
            </ion-tab-button>

            <ng-container *ngIf="(user$ | async) as user;">
                <div class="ion-hide-md-down tab-container">
                    <ion-tab-button tab="dashboard" layout=icon-start [ngClass]="getTab('dashboard')" href='/dashboard'>
                        <ion-icon name="analytics"></ion-icon>
                        <ion-label>Dashboard</ion-label>
                    </ion-tab-button>

                    <ion-tab-button *isAdmin tab="members" layout=icon-start [ngClass]="getTab('members')" href='/members'>
                        <ion-icon name="people"></ion-icon>
                        <ion-label>Members</ion-label>
                    </ion-tab-button>

                    <ion-tab-button tab="templates" layout=icon-start [ngClass]="getTab('templates')" hef='/templates'>
                        <ion-icon name="git-compare"></ion-icon>
                        <ion-label>Templates</ion-label>
                    </ion-tab-button>

                    <ion-tab-button tab="events" layout=icon-start [ngClass]="getTab('events')" href='/events'>
                        <ion-icon name="calendar"></ion-icon>
                        <ion-label>Events</ion-label>
                    </ion-tab-button>

                    <ion-tab-button tab="devicelist" layout=icon-start [ngClass]="getTab('devicelist')" href='/devicelist'>
                        <ion-icon name="aperture"></ion-icon>
                        <ion-label>Devices</ion-label>
                    </ion-tab-button>

                    <ion-tab-button tab="galleries" layout=icon-start [ngClass]="getTab('galleries')" href='/galleries'>
                        <ion-icon name="images"></ion-icon>
                        <ion-label>Galleries</ion-label>
                    </ion-tab-button>
                </div>

                <ion-menu-button layout=icon-start fill=clear menu=menu>
                    <ion-icon name="menu"></ion-icon>
                    <ion-label class=ion-hide-md-down>{{user.firstName + ' ' + user.lastName}}</ion-label>
                </ion-menu-button>

            </ng-container>
        </ion-tab-bar>

      </ion-tabs>

      <ion-menu side="end" menuId="menu" contentId="main" type=overlay>
        <ng-container *ngIf="(user$ | async) as user;">
            <ion-header>
            <ion-toolbar color="primary">
                <ion-buttons slot=start>
                    <ion-menu-toggle menu=menu>
                        <ion-label>Close</ion-label>
                    </ion-menu-toggle>
                </ion-buttons>
            </ion-toolbar>
            </ion-header>
            <ion-content>
            <ion-list>
                <div class=ion-hide-md-up>
                    <ion-item button detail=false (click)="navigate('/dashboard')">
                        <ion-icon name="analytics"></ion-icon>
                        <ion-label>Dashboard</ion-label>
                    </ion-item>
                    <ion-item button detail=false (click)="navigate('/members')" *isAdmin>
                        <ion-icon name="people"></ion-icon>
                        <ion-label>Members</ion-label>
                    </ion-item>
                    <ion-item button detail=false (click)="navigate('/clients')">
                        <ion-icon name="business"></ion-icon>
                        <ion-label>Clients</ion-label>
                    </ion-item>
                    <ion-item button detail=false (click)="navigate('/events')">
                        <ion-icon name="calendar"></ion-icon>
                        <ion-label>Events</ion-label>
                    </ion-item>
                    <ion-item button detail=false (click)="navigate('/devicelist')">
                        <ion-icon name="aperture"></ion-icon>
                        <ion-label>Devices</ion-label>
                    </ion-item>
                    <ion-item button detail=false (click)="navigate('/galleries')">
                        <ion-icon name="images"></ion-icon>
                        <ion-label>Galleries</ion-label>
                    </ion-item>
                </div>
                <ion-item button detail=false (click)="navigate('/admin-toolbox')" *isAdmin>
                    <ion-icon name="cog"></ion-icon>
                    <ion-label>Admin Toolbox</ion-label>
                </ion-item>
                <ion-item button detail=false (click)="navigate('/members', user.memberId)" *isAdmin=false>
                    <ion-icon name="people"></ion-icon>
                    <ion-label>Member</ion-label>
                </ion-item>
                <ion-item button detail=false (click)=logout()>
                    <ion-icon color=danger name="log-out"></ion-icon>
                    <ion-label color=danger>Logout</ion-label>
                </ion-item>
            </ion-list>
            </ion-content>
            <ion-footer class="ion-no-border">
                <ion-toolbar>
                    <ion-title>
                        {{ getUserRole(user) }}
                    </ion-title>
                </ion-toolbar>
            </ion-footer>
        </ng-container>
      </ion-menu>
</ion-app>
<ng-template #editorContainer>
</ng-template>
