import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Gallery } from '../models/gallery';
import { EnvService } from '../services/env/env.service';
import { ToastService } from '../services/toast/toast.service';
import { TableService } from './table-service';


@Injectable()
export abstract class GalleryTableService extends TableService<Gallery> {

    galleryType = '';

    constructor(
        private router: Router,
        http: HttpClient,
        envService: EnvService,
        toast: ToastService) {
        super(http, envService, toast);
    }

    public firstThreeImages(gallery: Gallery) {
        return gallery.images.slice(0, 3);
    }

    public openGallery(id, initial?) {
        const params = initial ? { queryParams: { image: initial.publicCode } } : {};
        this.router.navigate(['galleries', this.galleryType, id], params);
    }

    public isSelected(): boolean {
        return this.selected.length > 0;
    }

    public onActivate($event) {
        if ($event.type === 'dblclick') {
            this.openGallery($event.row.id, null);
        }
    }
}
