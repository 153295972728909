import { ModalController } from '@ionic/angular';

import { Input, Component } from '@angular/core';
import { IdsService } from 'src/app/services/ids/ids.service';

@Component({
  selector: 'app-attendee-show-barcode',
  templateUrl: './attendee-show-barcode.html',
  styleUrls: ['./attendee-show-barcode.scss']
})

export class AttendeeShowBarcodeModalComponent {

  @Input() attendee;
  @Input() eventId;
  freeText = [];
  newText = '';

  constructor(public modalCtrl: ModalController, public idsService: IdsService) {

  }

  close() {
    this.modalCtrl.dismiss();
  }


  printDiv() {
    const printContents = document.getElementById('printArea');

    // Open popup tab with print dialog
    const popupWin = window.open('', '_blank', 'width=300,height=300');
    popupWin.document.open();
    popupWin.document.title = 'Attendee Details';
    popupWin.document.write(`<html><head></head><body></body></html>`);

    popupWin.document.body.appendChild(printContents);
    const font = document.createElement('link');
    font.setAttribute('href', 'https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,700;1,400&display=swap');
    font.setAttribute('rel', 'stylesheet');
    popupWin.document.head.appendChild(font);
    popupWin.document.body.style.fontFamily = '\'Open Sans\', sans-serif';

    const script = document.createElement('script');
    script.innerText = 'window.print();';
    popupWin.document.head.appendChild(script);
    popupWin.document.close();
  }

  addField() {
    this.freeText.push(this.newText);
    this.newText = '';
  }

  getDateString() {
    return new Date().toString().split('G')[0];
  }

}
