import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TooltipDirective } from './tooltip/tooltip.directive';
import { TooltipComponent } from './tooltip/tooltip-component/tooltip.component';
import { DropdownDirective } from './dropdown/dropdown.directive';
import { DropdownComponent } from './dropdown/dropdown-component/dropdown.component';
import { ModuleDirective } from './module/module.directive';
import { ClickWaitDirective } from './click-wait/click-wait.directive';
import { AdminDirective } from './admin/admin.directive';
import { ManagerDirective } from './manager/manager.directive';

@NgModule({
  declarations: [
    TooltipDirective,
    TooltipComponent,
    DropdownDirective,
    DropdownComponent,
    ModuleDirective,
    ClickWaitDirective,
    AdminDirective,
    ManagerDirective
  ],
  imports: [
    CommonModule
  ],
  entryComponents: [
    TooltipComponent,
    DropdownComponent
  ],
  exports: [
    TooltipDirective,
    DropdownDirective,
    ModuleDirective,
    ClickWaitDirective,
    AdminDirective,
    ManagerDirective
  ]
})
export class DirectivesModule { }
