import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { IntegrationBuilder } from 'src/app/abstract/integration-builder';
import { EIEvent } from 'src/app/models/event';
import { EnvService } from '../env/env.service';
import { ToastService } from '../toast/toast.service';

@Injectable({
  providedIn: 'root'
})
export class XeroService implements IntegrationBuilder  {

  constructor(
    private http: HttpClient,
    private envService: EnvService,
    private toast: ToastService
  ) { }

  saveIntegration(memberId: number, code?: string) {
    this.http.post(this.envService.env.api + 'console/connected-accounts/authorize',
    { service: 'XERO', code, redirect: this.envService.env.redirectUri }).toPromise().then((response: any) => {
      this.toast.success('Connection with Xero successful!');
    }).catch((response) => {
      this.toast.error('Error connecting to Xero');
    });
  }

  failIntegration() {
    this.toast.error('Error connecting to Xero');  }

  newConfigModal(event: EIEvent, config?) {
    throw new Error('Method not implemented.');
  }
}
