import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from '../pipes/pipes.module';
import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';

import { LoadingComponent } from './loading/loading.component';
import { ContactColComponent } from './contact-col/contactCol.component';
import { RecentImagesComponent } from './recent-images/recentImages.component';
import { WatermarkUploadComponent } from './watermark-upload/watermarkUpload.component';
import { TimelineChartComponent } from './timeline-chart/timeline-chart.component';
import {
  BarChartModule,
  LineChartModule,
  PieChartModule,
} from '@swimlane/ngx-charts';
import { DateFnsModule } from 'ngx-date-fns';
import { BannerUploadComponent } from './banner-upload/banner-upload.component';
import { SelectWrapperComponent } from './select-wrapper/select-wrapper.component';
import { DateTimePickerComponent } from './date-time-picker/date-time-picker.component';
import { CrossFadeImageComponent } from './cross-fade-image/cross-fade-image.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { InfoTileComponent } from './info-tile/info-tile.component';
import { CountUpModule } from 'ngx-countup';
import { TableSearchComponent } from './table-search/table-search.component';
import { EventTileComponent } from './event-tile/event-tile.component';
import { SearchSelectComponent } from './search-select/search-select.component';
import { ExternalSearchSelectComponent } from './external-search-select/external-search-select.component';
import { StepperModalComponent } from './stepper-modal/stepper-modal.component';
import { ModalStepperComponent } from './modal-stepper/modal-stepper.component';
import { AttendeeTableComponent } from './attendee-table/attendee-table.component';
import { CardTableComponent } from './card-table/card-table.component';
import { OtherTableComponent } from './other-table/other-table.component';
import { RowDetailComponent } from './row-detail/row-detail.component';
import { CardToolbarComponent } from './card-toolbar/card-toolbar.component';
import { AttendeeToolbarComponent } from './attendee-toolbar/attendee-toolbar.component';
import { OtherIdToolbarComponent } from './other-id-toolbar/other-id-toolbar.component';
import { CardTableService } from './card-table/card-table.service';
import { OtherTableService } from './other-table/other-table.service';
import { AttendeeTableService } from './attendee-table/attendee-table.service';
import { IntegrationHintComponent } from './integration-hint/integration-hint.component';
import { PublicTableComponent } from './public-table/public-table.component';
import { PublicToolbarComponent } from './public-toolbar/public-toolbar.component';
import { PublicTableService } from './public-table/public-table.service';
import { GlobalSpinnerComponent } from './global-spinner/global-spinner.component';
import { InlineModule } from '../../inline/inline.module';
import { AlertTileComponent } from './alert-tile/alert-tile.component';
import { TypeaheadComponent } from './typeahead/typeahead.component';
import { SubjectChipsComponent } from './subject-chips/subject-chips.component';
import { MediaComponent } from './media/media.component';
import { MediaThumbComponent } from './media-thumb/media-thumb.component';
import { DeviceTableComponent } from './device-table/device-table.component';
import { CellStrengthComponent } from './cell-strength/cell-strength.component';
import { GpsLocComponent } from './gps-loc/gps-loc.component';
import { BatteryStatusComponent } from './battery-status/battery-status.component';
import { DirectivesModule } from '../directives/directives.module';
import { ModalFooterComponent } from './modal-footer/modal-footer.component';
import { ModalHeaderComponent } from './modal-header/modal-header.component';
import { CopyTextComponent } from './copy-text/copy-text.component';
import { JobManagerComponent } from './job-manager/job-manager.component';
import { PreviewRegistrationComponent } from './preview-registration/preview-registration.component';
import { IntegrationDropboxComponent } from './integration-dropbox/integration-dropbox.component';
import { IntegrationMessagingEmailComponent } from './integration-messaging-email/integration-messaging-email.component';
import { IntegrationMessagingSmsComponent } from './integration-messaging-sms/integration-messaging-sms.component';
import { IntegrationFacialComponent } from './integration-facial/integration-facial.component';
import { UploadAreaComponent } from './upload-area/upload-area.component';
import { ConfigDeviceComponent } from './config-device/config-device.component';
import { ConfigOcrComponent } from './config-ocr/config-ocr.component';
import { NgxCurrencyInputModule } from 'ngx-currency-input';
import { SalesComponent } from './sales/sales.component';
import { NgxCurrencyModule } from 'ngx-currency';
import { PhoneInputComponent } from './phone-input/phone-input.component';
import { HighResTableComponent } from './highres-table/highres-table.component';
import { HiResTableService } from './highres-table/highres-table.service';
import { HighResToolbarComponent } from './highres-toolbar/highres-toolbar.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    PipesModule,
    DropzoneModule,
    RouterModule,
    BarChartModule,
    LineChartModule,
    PieChartModule,
    DateFnsModule,
    InlineModule,
    CountUpModule,
    NgxDatatableModule,
    ReactiveFormsModule,
    DirectivesModule,
    NgxCurrencyInputModule,
    NgxCurrencyModule,
  ],
  declarations: [
    ContactColComponent,
    RecentImagesComponent,
    LoadingComponent,
    WatermarkUploadComponent,
    TimelineChartComponent,
    BannerUploadComponent,
    SelectWrapperComponent,
    DateTimePickerComponent,
    CrossFadeImageComponent,
    AttendeeTableComponent,
    CardTableComponent,
    OtherTableComponent,
    HighResTableComponent,
    HighResToolbarComponent,
    RowDetailComponent,
    CardToolbarComponent,
    AttendeeToolbarComponent,
    OtherIdToolbarComponent,
    InfoTileComponent,
    TableSearchComponent,
    EventTileComponent,
    SearchSelectComponent,
    ExternalSearchSelectComponent,
    StepperModalComponent,
    IntegrationHintComponent,
    PublicTableComponent,
    PublicToolbarComponent,
    GlobalSpinnerComponent,
    AlertTileComponent,
    TypeaheadComponent,
    SubjectChipsComponent,
    MediaComponent,
    MediaThumbComponent,
    DeviceTableComponent,
    CellStrengthComponent,
    GpsLocComponent,
    BatteryStatusComponent,
    ModalFooterComponent,
    ModalHeaderComponent,
    CopyTextComponent,
    JobManagerComponent,
    ModalStepperComponent,
    PreviewRegistrationComponent,
    IntegrationDropboxComponent,
    IntegrationMessagingEmailComponent,
    IntegrationMessagingSmsComponent,
    IntegrationFacialComponent,
    UploadAreaComponent,
    ConfigDeviceComponent,
    ConfigOcrComponent,
    SalesComponent,
    PhoneInputComponent,
  ],
  exports: [
    SalesComponent,
    ModalFooterComponent,
    ModalHeaderComponent,
    ContactColComponent,
    RecentImagesComponent,
    LoadingComponent,
    WatermarkUploadComponent,
    TimelineChartComponent,
    BannerUploadComponent,
    SelectWrapperComponent,
    DateTimePickerComponent,
    CrossFadeImageComponent,
    AttendeeTableComponent,
    CardTableComponent,
    OtherTableComponent,
    HighResTableComponent,
    HighResToolbarComponent,
    InfoTileComponent,
    TableSearchComponent,
    EventTileComponent,
    SearchSelectComponent,
    ExternalSearchSelectComponent,
    StepperModalComponent,
    IntegrationHintComponent,
    PublicTableComponent,
    GlobalSpinnerComponent,
    AlertTileComponent,
    TypeaheadComponent,
    SubjectChipsComponent,
    MediaComponent,
    MediaThumbComponent,
    DeviceTableComponent,
    CellStrengthComponent,
    GpsLocComponent,
    BatteryStatusComponent,
    CopyTextComponent,
    JobManagerComponent,
    ModalStepperComponent,
    PreviewRegistrationComponent,
    IntegrationDropboxComponent,
    IntegrationMessagingEmailComponent,
    IntegrationMessagingSmsComponent,
    IntegrationFacialComponent,
    UploadAreaComponent,
    ConfigDeviceComponent,
    ConfigOcrComponent,
    PhoneInputComponent,
  ],
  providers: [
    CardTableService,
    OtherTableService,
    AttendeeTableService,
    PublicTableService,
    HiResTableService,
  ],
})
export class ComponentsModule {}
