<div class="upload-jobs" *ngIf="jobs.length > 0">
    <div class="upload-header">
        {{ allJobsComplete() ? 'All jobs finished!' : 'Running bulk jobs...'}}
    </div>

    <div class="upload-job" *ngFor="let job of jobs">
        <div class="left-side">
            <div class="header">
                <div class="job-name"><ion-icon [name]="job.jobIcon"></ion-icon><strong>{{job.jobName}}</strong></div>
                <div [hidden]="job.processedItems.length + job.failedItems.length == 0"class="job-progress" [ngClass]="{'done': job.processedItems.length + job.failedItems.length == job.items.length}"><span class="job-percentage">{{job.processedItems.length + job.failedItems.length}} / {{job.items.length}}</span> complete</div>
                <div [hidden]="job.processedItems.length + job.failedItems.length != 0" class="job-progress"><span class="job-percentage">Starting job...</span></div>
            </div>
            <div class="body">
                <div class="job-progress-bar">
                    <div class="status" [style.width.%]="((job.processedItems.length + job.failedItems.length)/job.items.length) * 100" [ngClass]="{'done': job.processedItems.length + job.failedItems.length == job.items.length}"></div>
                </div>
            </div>
            <div class=failed [hidden]="job.failedItems.length == 0">Failed: {{job.failedItems.length}}</div>

        </div>
        <div class="action-button" (click)="jobActionButton(job)">
            <ion-icon [hidden]="job.processedItems.length + job.failedItems.length == job.items.length" color=danger name="close-outline"></ion-icon>
            <ion-icon [hidden]="job.processedItems.length + job.failedItems.length != job.items.length" color=success name="checkmark-outline"></ion-icon>
        </div>

    </div>
</div>
