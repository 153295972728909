import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvService } from 'src/app/services/env/env.service';
import { Router } from '@angular/router';
import { ToastService } from 'src/app/services/toast/toast.service';
import { GalleryTableService } from 'src/app/abstract/gallery-table-service';

// Jonathon Martin, Elementice, March 2020

@Injectable()

export class HiResTableService extends GalleryTableService {

    constructor(
        http: HttpClient,
        envService: EnvService,
        toast: ToastService,
        router: Router) {
        super(router, http, envService, toast);
        this.galleryType = 'highres';
        this.url = 'galleries/' + this.galleryType + '/list';
    }

}
