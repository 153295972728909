import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { AbilityModule } from '@casl/angular';
import { SharedModule } from './shared/shared.module';
import { CardPricingService } from './services/card-pricing/cardPricing.service';
import { CognitoService } from './services/cognito/cognito.service';
import { ConsoleStateService } from './services/console-state/consoleState.service';
import { EnvService } from './services/env/env.service';
import { IdsService } from './services/ids/ids.service';
import { IntegrationService } from './services/integration/integration.service';
import { AuthInterceptorService } from './interceptors/auth-interceptor.service';
import { MessageService } from './services/message/message.service';
import { SocketService } from './services/sockets/sockets.service';
import { UserService } from './services/user/user.service';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { StripeService } from './services/stripe/stripe.service';
import { DropboxService } from './services/dropbox/dropbox.service';
import { IonicModule } from '@ionic/angular';
import { DateFnsModule } from 'ngx-date-fns';
import { LazyLoaderService } from './services/lazy-loader/lazy-loader.service';
import { LAZY_WIDGETS } from './services/lazy-loader/tokens';
import { lazyArrayToObj } from './services/lazy-loader/lazy-widgets';
import { EventGalleryService } from './services/event-gallery/event-gallery.service';
import { DeviceGalleryService } from './services/device-gallery/device-gallery.service';
import { AttendeeGalleryService } from './services/attendee-gallery/attendee-gallery.service';
import { CardGalleryService } from './services/card-gallery/card-gallery.service';
import { OtherGalleryService } from './services/other-gallery/other-gallery.service';
import { InlineModule } from './inline/inline.module';
import { EVENT_SERVICE_TOKEN } from './services/event-gallery/token';
import { DEVICE_SERVICE_TOKEN } from './services/device-gallery/token';
import { CARD_SERVICE_TOKEN } from './services/card-gallery/token';
import { ATTENDEE_SERVICE_TOKEN } from './services/attendee-gallery/token';
import { OTHER_SERVICE_TOKEN } from './services/other-gallery/token';
import { PendingInterceptorService } from './interceptors/pending-interceptor.service';
// Analytics
import { Angulartics2Module } from 'angulartics2';
import { environment } from '../environments/environment';

@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    IonicModule.forRoot({
      mode: 'ios'
    }),
    LoggerModule.forRoot({level: NgxLoggerLevel.ERROR}),
    BrowserAnimationsModule,
    NgxWebstorageModule.forRoot(),
    SharedModule,
    InlineModule,
    DateFnsModule.forRoot(),
    // Analytics
    Angulartics2Module.forRoot({
      pageTracking: {
        clearHash: true,
        clearQueryParams: true,
        clearIds: true,
        excludedRoutes: [/\/[0-9]{4}\/[0-9]{2}\/[a-zA-Z0-9|\-]*/],
      },
      gst: {
        trackingIds: [environment.googleAnalyticsID],
        anonymizeIp: false
      },
    })
  ],
  declarations: [
    AppComponent,
  ],
  entryComponents: [
    AppComponent,
  ],
  providers: [
    CardPricingService,
    CognitoService,
    ConsoleStateService,
    EnvService,
    IdsService,
    IntegrationService,
    StripeService,
    DropboxService,
    AuthInterceptorService,
    MessageService,
    SocketService,
    UserService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: PendingInterceptorService,
      multi: true
    },
    LazyLoaderService,
    { provide: LAZY_WIDGETS, useFactory: lazyArrayToObj },
    {
        provide: EVENT_SERVICE_TOKEN,
        useClass: EventGalleryService
    },
    {
        provide: DEVICE_SERVICE_TOKEN,
        useClass: DeviceGalleryService
    },
    {
      provide: CARD_SERVICE_TOKEN,
      useClass: CardGalleryService
    },
    {
      provide: ATTENDEE_SERVICE_TOKEN,
      useClass: AttendeeGalleryService
    },
    {
    provide: OTHER_SERVICE_TOKEN,
    useClass: OtherGalleryService
  }
  ],

  bootstrap: [AppComponent]
})

export class AppModule {
  constructor() { }
}






































