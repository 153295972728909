<ng-container *ngIf=identity>
    <ng-container>
        <app-stepper-modal
        [title]="[newAttendee ? 'Add Attendee' : 'Edit Attendee']"
        [valid]=isValid(currentTemplate)
        [templates]="[{ name: 'editIdentity', template: editIdentity}]"
        [(currentTemplate)]=currentTemplate
        (cancel)=close()
        (endForm)=save()>
        </app-stepper-modal>
    </ng-container>
</ng-container>

<ng-template #editIdentity>
    <ion-grid style="height: 100%">
        <ion-row>
            <ion-col size=12>
                <h4>Details</h4>
            </ion-col>
        </ion-row>
        <ion-row style="height: 92%; overflow-y: auto;">
            <ion-col size=12>
                <ion-item>
                    <ion-label position="stacked" class="required-field">
                        First Name <span>*</span>
                    </ion-label>
                    <ion-input required=true type=text name=firstName [(ngModel)]=identity.firstName></ion-input>
                </ion-item>
                <ion-item>
                    <ion-label position="stacked">
                        Last Name
                    </ion-label>
                    <ion-input type=text name=lastName [(ngModel)]=identity.lastName></ion-input>
                </ion-item>
                <ion-item>
                    <ion-label position="stacked" class="required-field">
                        Attendee ID <span>*</span>
                    </ion-label>
                    <ion-input type=text required=true name=galleryId [(ngModel)]=newGalleryId></ion-input>
                </ion-item>
                <ion-item>
                    <ion-label position="stacked">
                        Company
                    </ion-label>
                    <ion-input type=text name=company [(ngModel)]=identity.company></ion-input>
                </ion-item>
                <div class="phone-input">
                    <ion-label position="stacked">
                        Mobile Number
                    </ion-label>
                    <app-phone-input [(value)]=identity.mobileNumber [(valid)]=mobileValid></app-phone-input>
                </div>
                <div class="phone-input">
                    <ion-label position="stacked">
                        Secondary Mobile Number
                    </ion-label>
                    <app-phone-input [(value)]=identity.secondaryMobileNumber [(valid)]=secondaryMobileValid></app-phone-input>
                </div>
                <ion-item>
                    <ion-label position="stacked">
                        Email Address
                    </ion-label>
                    <ion-input type=email name=emailAddress [(ngModel)]=identity.emailAddress></ion-input>
                </ion-item>
                <ion-item>
                    <ion-label position="stacked">
                    Secondary Email Address
                    </ion-label>
                    <ion-input type=email name=secondaryEmailAddress [(ngModel)]=identity.secondaryEmailAddress></ion-input>
                </ion-item>
                <ion-item>
                    <ion-label position="stacked">
                        Notes
                    </ion-label>
                    <ion-textarea type=text name=notes [(ngModel)]=identity.notes></ion-textarea>
                </ion-item>
                <ion-item *ngIf=!newAttendee>
                    <ion-label position="stacked" class="required-field">
                        Event <span>*</span>
                    </ion-label>
                    <div style="width: 100%;">
                        <app-search-select [list]=idsService.getEventsForSelect() placeholder="Choose Event" [(deleteEventId)]=deleteEventId [(value)]=eventId></app-search-select>
                    </div>
                </ion-item>
                <ion-item style="height: 30px">
                </ion-item>
            </ion-col>
        </ion-row>
        <ion-row *ngIf=loading>
            <ion-col size=12>
                <app-loading></app-loading>
            </ion-col>
        </ion-row>
    </ion-grid>
</ng-template>
