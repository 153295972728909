import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ConnectionStateService } from 'src/app/services/connection-state/connection-state.service';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {

  @Input() loadingText = '';
  @Input() retryText = 'Something went wrong...';
  @Input() failed = false;
  @Input() size = 'large';
  @Output() retry = new EventEmitter<any>();


  constructor(private connection: ConnectionStateService) { }

  ngOnInit() {
    this.connection.state$.subscribe(connected => {
      if (connected && this.failed) {
        this.retryRequest();
      }
    });
  }

  retryRequest() {
    this.failed = false;
    setTimeout(() => {
      this.retry.emit();
    }, 1000);
  }


}
