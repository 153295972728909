import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

import { FileUploadModalComponent } from './file-upload/fileUpload.component';
import { NewIntegrationModalComponent } from './integration-new/integrationNew.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { RouterModule } from '@angular/router';
import { PipesModule } from '../pipes/pipes.module';
import { ComponentsModule } from '../components/components.module';
import { ImageUploadModalComponent } from './image-upload/imageUpload.component';
import { AttendeeEditModalComponent } from './attendee-edit/attendeeEdit.component';
import { AttendeeShowBarcodeModalComponent } from './attendee-show-barcode/attendeeShowBarcode.component';
import { AttendeeGenerateBarcodeSheetComponent } from './attendee-generate-barcode-sheet/attendeeGenerateBarcodeSheet.component';
import { AttendeesReviewModalComponent } from './attendees-review/attendeesReview.component';
import { CardPublicNewModalComponent } from './card-public-new/cardPublicNew.component';
import { DateFnsModule } from 'ngx-date-fns';
import { DirectivesModule } from '../directives/directives.module';
import { QrCodeModule } from 'ng-qrcode';
import { SwiperModule } from 'swiper/angular';
import { FotomerchantConfigComponent } from './integration-config-fotomerchant/integrationConfigFotomerchant.component';
import { DropboxConfigComponent } from './integration-config-dropbox/integration-config-dropbox.component';
import { SearchSelectModalComponent } from './search-select-modal/search-select-modal.component';
import { MessagingConfigComponent } from './integration-config-messaging/integration-config-messaging.component';
import { IntegrationConfigFacialComponent } from './integration-config-facial/integration-config-facial.component';
import { ReferenceImageComponent } from './reference-image/reference-image.component';
import { PickerComponent } from './picker/picker.component';
import { ShowQrComponent } from './show-qr/show-qr.component';
import { IntegrationConfigEnhancementComponent } from './integration-config-enhancement/integration-config-enhancement.component';
import { IntegrationConfigOcrComponent } from './integration-config-ocr/integration-config-ocr.component';
import { IntegrationConfigGfpComponent } from './integration-config-gfp/integration-config-gfp.component';
import { ConfigRegistrationComponent } from './config-registration/config-registration.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    DropzoneModule,
    RouterModule,
    PipesModule,
    ComponentsModule,
    DirectivesModule,
    ReactiveFormsModule,
    IonicModule,
    DateFnsModule,
    QrCodeModule,
    SwiperModule
  ],
  declarations: [
    FileUploadModalComponent,
    NewIntegrationModalComponent,
    ImageUploadModalComponent,
    AttendeeEditModalComponent,
    AttendeeShowBarcodeModalComponent,
    AttendeeGenerateBarcodeSheetComponent,
    AttendeesReviewModalComponent,
    CardPublicNewModalComponent,
    FotomerchantConfigComponent,
    DropboxConfigComponent,
    SearchSelectModalComponent,
    MessagingConfigComponent,
    IntegrationConfigFacialComponent,
    ReferenceImageComponent,
    PickerComponent,
    ShowQrComponent,
    IntegrationConfigEnhancementComponent,
    IntegrationConfigOcrComponent,
    IntegrationConfigGfpComponent,
    ConfigRegistrationComponent
  ],
  entryComponents: [
    FileUploadModalComponent,
    NewIntegrationModalComponent,
    ImageUploadModalComponent,
    AttendeeEditModalComponent,
    AttendeeShowBarcodeModalComponent,
    AttendeeGenerateBarcodeSheetComponent,
    AttendeesReviewModalComponent,
    CardPublicNewModalComponent,
    FotomerchantConfigComponent,
    DropboxConfigComponent,
    SearchSelectModalComponent,
    MessagingConfigComponent,
    ReferenceImageComponent,
    PickerComponent,
    ShowQrComponent,
    ConfigRegistrationComponent
  ],
})
export class ModalsModule {}
