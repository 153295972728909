import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, AfterViewInit, Output, ViewChild, OnDestroy } from '@angular/core';
import { IonSearchbar } from '@ionic/angular';
import { BehaviorSubject, combineLatest, Subject, Subscription } from 'rxjs';
import { map, startWith, take } from 'rxjs/operators';
import { EnvService } from 'src/app/services/env/env.service';

@Component({
  selector: "app-typeahead",
  templateUrl: "./typeahead.component.html",
  styleUrls: ["./typeahead.component.scss"],
})
export class TypeaheadComponent implements AfterViewInit, OnDestroy {

  @ViewChild('searchbar') searchbar: IonSearchbar;
  searchResults$ = new Subject<any[]>()
  filteredSearchResults$ = this.searchResults$.pipe(map(results => {
    if (this.showDuplicates) return results
    return results ? results.filter((r) => !this.isDuplicate(this.values, r.id)) : results
  }))

  loading$ = new BehaviorSubject<boolean>(false);

  @Input() searchFor: string = 'galleries';
  @Input() values: Array<any> = []
  @Input() displayField = 'id'
  @Input() additionalFields = ['firstName', 'lastName']
  @Input() placeholder = "Search Subjects (Case Sensitive)"
  @Input() member: number;
  @Input() event: string;
  @Input() disabled = false;
  @Input() allowNew = false;
  @Input() showDuplicates = true;
  @Input() showResults = true;

  @Input() searchValue: string;
  @Output() searchValueChange = new EventEmitter<string>();

  @Output() addNew = new EventEmitter<any>();
  @Output() add = new EventEmitter<any>();

  filterSub: Subscription;

  constructor(private http: HttpClient, private envService: EnvService) {}

  ngAfterViewInit(): void {
    this.filterSub = combineLatest([
      this.filteredSearchResults$.pipe(startWith(null)),
      this.loading$,
    ]).subscribe(([rs, loading]) => {
      this.searchbar.getInputElement().then((el) => {
        if ((rs && (rs.length > 0 || this.allowNew)) || loading) {
          el.style.setProperty('border-bottom-left-radius', `unset`);
          el.style.setProperty('border-bottom-right-radius', `unset`);
        } else {
          el.style.setProperty("border-bottom-left-radius", `10px`);
          el.style.setProperty("border-bottom-right-radius", `10px`);
        }
      });
    });
  }

  ngOnDestroy() {
    if (this.filterSub) {
      this.filterSub.unsubscribe();
    }
  }

  getDisplayString(result) {
    let str = result[this.displayField] ? result[this.displayField] + ' ' : '';
    const extra = this.additionalFields
      .map((df) => (result[df] ? result[df] : ''))
      .join(' ')
      .trim();
    if (extra) {
      str += '(' + extra + ')';
    }
    return str;
  }

  selectUnknown() {
    this.addNew.emit(this.searchValue);
    this.searchValue = null;
  }

  selectResult(result) {
    this.add.emit(result);
    this.searchValue = null;
  }

  setSearch($event) {
    this.searchValue = $event ? $event.detail.value.trim() : null;
    this.searchValueChange.emit(this.searchValue)
    if (this.searchValue) {
      this.loading$.next(true);
      this.http
        .get<any[]>(
          this.envService.env.api +
            this.searchFor +
            '/ids?search=' +
            this.searchValue +
            ';member=' +
            this.member
        )
        .pipe(take(1))
        .subscribe((results) => {
          this.searchResults$.next(results);
          this.loading$.next(false);
        });
    } else {
      this.searchResults$.next(null);
    }
  }

  isDuplicate(ids, id: string): boolean {
    return ids.some((s) => s.id === id);
  }
}
