<ion-footer>
    <ion-toolbar>
        <ion-grid style="padding-top: 0px">
            <ion-row *ngIf=info>
                <ion-col size=12>
                    <div *ngIf=infoHeader>
                        {{infoHeader}}
                    </div>
                    <small>{{ info }}</small>
                </ion-col>
            </ion-row>
            <ion-row>
                <ion-col size=4>
                    <ion-button fill=clear [color]=leftColor (click)=left.emit()>
                        <ion-icon [name]="leftIcon"></ion-icon>{{leftLabel}}
                    </ion-button>
                </ion-col>
                <ion-col size=4 class=ion-text-center>
                    <ion-button class=action [hidden]=!showAction size=small [color]=actionColor shape=round fill=outline (click)=action.emit() [disabled]="actionDisabled">
                        <ion-label>{{actionLabel}}</ion-label>
                    </ion-button>
                </ion-col>
                <ion-col size=4>
                    <ion-button *ngIf="showRight && !rightLoading" class=next [color]=rightColor fill=clear (click)=right.emit() [disabled]="rightDisabled">
                        {{rightLabel}}<ion-icon [name]="rightIcon"></ion-icon>
                    </ion-button>
                    <ion-spinner *ngIf="rightLoading" class=spinner [color]=rightColor name=bubbles>
                    </ion-spinner>
                </ion-col>
            </ion-row>
        </ion-grid>
    </ion-toolbar>
</ion-footer>
