import { HttpClient } from '@angular/common/http';
import { EnvService } from 'src/app/services/env/env.service';
import { Input, Component, AfterViewInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { IdsService } from 'src/app/services/ids/ids.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { Gallery } from 'src/app/models/gallery';
import { TemplateSlide } from 'src/app/models/template-slide';


@Component({
  selector: 'app-attendee-edit',
  templateUrl: './attendee-edit.html',
  styleUrls: ['attendee-edit.scss']
})

export class AttendeeEditModalComponent implements AfterViewInit {

  @Input() eventId: string;
  @Input() deleteEventId: any;
  @Input() identity: any = {};
  @Input() gallery: Gallery;
  @Input() newAttendee = false;

  mobileValid = true;
  secondaryMobileValid = true;
  currentTemplate: TemplateSlide;
  loading = false;
  newGalleryId: string;

  constructor(
    private envService: EnvService,
    private http: HttpClient,
    public modalCtrl: ModalController,
    public idsService: IdsService,
    private toast: ToastService
  ) {}

  ngAfterViewInit() {
    setTimeout(() => {
      // Generate a random gallery id if we're creating a new attendee
      this.newGalleryId = this.gallery?.id || Math.random().toString(36).substring(2,10).toUpperCase();
    }, 0);
  }

  isValid(template: TemplateSlide): boolean {
    if (!template) {return false;}

    // Deleted event id is set, but no new item is there to replace it, this is invalid
    // Must always set a new event when deleting one.
    if (this.deleteEventId && !this.eventId) {
      return false;
    }

    return (this.mobileValid || this.identity.mobileNumber === '')
      && (this.secondaryMobileValid || this.identity.secondaryMobileNumber === '')
      && this.identity.firstName;
  }


  async save() {
    this.loading = true;

    try {
      let identityResponse;

      if (this.identity._id) {
        // Update attendee if one already exists
        identityResponse = await this.http.patch(this.envService.env.api + 'identities/' + this.identity._id, this.identity).toPromise();
      } else {
        this.identity.id = this.newGalleryId;
        if (this.gallery) {
          // Create new identity for existing galleries being converted to attendees
          identityResponse = await this.http.post(this.envService.env.api + 'identities', this.identity).toPromise();
        } else {
          // Otherwise, create a new attendee (which will also create and associate a new identity)
          const url = this.envService.env.api + 'events/' + this.eventId + '/attendee/new';
          identityResponse = await this.http.post(url, [this.identity]).toPromise();
        }
      }

      // Update gallery information if one is provided
      if (this.gallery) {
        const galleyBody: {
          identity: any;
          event: any;
          id: any;
          member: any;
          __t: string;
          deleteEventId?: any;
        } = {
          identity: identityResponse._id,
          event: this.eventId,
          id: this.newGalleryId,
          member: identityResponse.memberId,
          __t: 'ATTENDEE'
        };

        // Specify to API to delete the currently assigned event
        if (this.deleteEventId) {
          galleyBody.deleteEventId = this.deleteEventId;
        }

        await this.http.patch(this.envService.env.api + 'galleries/' + this.gallery._id, galleyBody).toPromise();
      }

      this.close();
      this.toast.success('Attendee saved!');
    } catch (e) {
      console.error(e);
      this.toast.error('Attendee save failed...');
    } finally {
      this.loading = false;
    }
  }

  close() {
    this.modalCtrl.dismiss();
  }
}
