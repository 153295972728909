import { Injectable } from '@angular/core';

// eslint-disable-next-line @typescript-eslint/ban-types
declare let gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class GAnalyticsService {

  private scriptTag1 = document.createElement('script');
  private scriptTag2 = document.createElement('script');
  private strScriptText = '';
  private eventLabel: string;
  private eventComponent: string;
  private eventType: string;

  constructor() {
  }

  public insertGTag(googleAnalyticsUrl){
    this.scriptTag1.src = googleAnalyticsUrl;
    this.scriptTag1.async = true;
    document.head.appendChild(this.scriptTag1);

    this.strScriptText = 'window.dataLayer = window.dataLayer || []; ';
    this.strScriptText = this.strScriptText.concat('function gtag(){window.dataLayer.push(arguments);} ');
    this.strScriptText = this.strScriptText.concat('gtag(\'js\', new Date()); ');
    this.scriptTag2.text = this.strScriptText;
    document.head.appendChild(this.scriptTag2);
  }

}
