import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-modal-header',
  templateUrl: './modal-header.component.html',
  styleUrls: ['./modal-header.component.scss']
})
export class ModalHeaderComponent implements OnInit {

  @Input() title = '';
  @Input() info = '';
  @Input() instructions = '';
  @Input() actions = [];

  @Input() pagination? : boolean;

  @Output() action = new EventEmitter<number>();

  constructor() { }

  ngOnInit(): void {
  }

}
