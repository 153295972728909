<div class=subject-chip *ngFor="let subject of subjects">
    <ion-chip (click)=chipAction(subject) [class]=color> 
        <ng-container [ngSwitch]=subject.__t>
            <ion-icon [color]="iconColor()" *ngSwitchCase="'CARD'"src="/assets/img/favicon.svg"></ion-icon>
            <ion-icon [color]="iconColor() "*ngSwitchCase="'ATTENDEE'" name=person></ion-icon>
            <ion-icon [color]="iconColor() "*ngSwitchDefault name=help-circle-outline></ion-icon>
        </ng-container>
        
        <ion-label [color]="textColor()">{{subject.id}}</ion-label>
        <ion-icon [color]="actionIconColor()" *ngIf="action === 'remove'" name="close"></ion-icon>
    </ion-chip>

</div>
<ng-container *ngIf="action === 'open'">
    <ion-chip color=primary (click)="reassign.emit('add')">
        <ion-icon name="add-circle-outline"></ion-icon> <ion-label>ADD</ion-label> 
    </ion-chip>
    <ion-chip color=primary (click)="reassign.emit('replace')">
        <ion-icon name="repeat-outline"></ion-icon> <ion-label>REPLACE </ion-label>
    </ion-chip>
    <ion-chip [disabled]="subjects.length==0" color=danger (click)="reassign.emit('remove')">
        <ion-icon name="remove-circle-outline"></ion-icon> <ion-label>REMOVE</ion-label>
    </ion-chip>
</ng-container>
<ng-container *ngIf="action === 'remove' && subjects.length==0">
   <p class=ion-text-center>No subjects</p> 
</ng-container>
