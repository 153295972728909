import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvService } from 'src/app/services/env/env.service';
import { GalleryService } from 'src/app/abstract/gallery-service';
import { MessageService } from 'src/app/services/message/message.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class EventGalleryService extends GalleryService {

  url = 'galleries/events/';
  devicesUrl = 'galleries/active-devices/events/';

  constructor(
    http: HttpClient,
    envService: EnvService,
    private router: Router,
    messages: MessageService) {
      super(router, http, envService, messages);
    }


  open(initial?: any) {
    const params = initial ? { queryParams: { image: initial.publicCode } } : {};
    this.router.navigate(['galleries', 'events', this.id], params);
  }




}
