import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchPipe } from './search.pipe';
import { SortPipe } from './sort.pipe';
import { FilterPipe } from './filter.pipe';
import { ToDatePipe } from './to-date.pipe';
import { ToMinutePipe } from './to-minute.pipe';
import { HighlightPipe } from './highlight.pipe';

@NgModule({
  declarations: [
    SearchPipe,
    SortPipe,
    FilterPipe,
    ToDatePipe,
    ToMinutePipe,
    HighlightPipe,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    SearchPipe,
    SortPipe,
    FilterPipe,
    ToDatePipe,
    ToMinutePipe,
    HighlightPipe,
  ]
})
export class PipesModule { }
