import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {

  transform(items: any[], field: string, value: string, invert: boolean = false): any[] {
    if (!value) {
      return items;
    }
    if (!items) {
      return [];
    }
    if (!field) {
      return items.filter(it => it.toUpperCase().includes(value.toUpperCase()) !== invert);
    }
    if (field.includes('.')) {
      const fields = field.split('.');
      return items.filter(it => it[fields[0]][fields[1]].toUpperCase().includes(value.toUpperCase()) !== invert);
    }
    return items.filter(it => it[field].toUpperCase().includes(value.toUpperCase()) !== invert);
  }

}
