
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvService } from 'src/app/services/env/env.service';
import { MessageService } from 'src/app/services/message/message.service';
import { GalleryService } from 'src/app/abstract/gallery-service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class DeviceGalleryService extends GalleryService {

  url = 'galleries/devices/';

  constructor(
    http: HttpClient,
    envService: EnvService,
    private router: Router,
    messages: MessageService) {
      super(router, http, envService, messages);
    }

  open(initial?: any) {
    const params = initial ? { queryParams: { image: initial.publicCode } } : {};
    this.router.navigate(['galleries', 'devices', this.id], params);
  }

}
