import { Injectable } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { ConsoleStateService } from '../console-state/consoleState.service';
import { StripeService } from '../stripe/stripe.service';
import { DropboxService } from '../dropbox/dropbox.service';
import { IdsService } from '../ids/ids.service';
import { filter } from 'rxjs/operators';
import { XeroService } from '../xero/xero.service';

@Injectable({
  providedIn: 'root'
})
export class UrlObserverService {

  constructor(
    private route: ActivatedRoute,
    private logger: NGXLogger,
    private router: Router,
    private stripe: StripeService,
    private xero: XeroService,
    private dropbox: DropboxService,
    private idsService: IdsService,
    private consoleState: ConsoleStateService) {
    this.route.queryParamMap.pipe(filter(qp => !qp.has('image'))).subscribe(queryParams => {
      this.logger.info('Query params:', queryParams);
      this.checkStripeState(queryParams);
      this.checkDropBoxState(queryParams);
      this.checkXeroState(queryParams);
    });
  }

  private cleanUpParams() {
    this.router.navigate([]);
  }

  // code=a962b4491869f4ab5f4ff1d52749355edc2359824d053732b93cb6f465db5a44&
  // scope=openid%20accounting.contacts%20accounting.transactions&
  // session_state=EbDHkMapBP4siCvUdgDTLTsl0ExkRZXmMqyQR2wS5ok.2b67155aae642b1e9e6dab8c013f3761
  private checkXeroState(queryParams: ParamMap) {
    const xeroState = this.consoleState.get('XERO');
    //console.log(stripeState)
    if (xeroState === 'pending') {
      const code = queryParams.get('code');
      if (code) {
        this.cleanUpParams();
        this.logger.info('Got code from Xero!');
        this.xero.saveIntegration(null, code);
        this.consoleState.set('XERO', 'complete');
      } else {
        const error = queryParams.get('error');
        if (error) {
          this.xero.failIntegration();
          this.cleanUpParams();
        }
      }
    } else {
      this.consoleState.delete('XERO');
    }
  }

  private checkStripeState(queryParams) {
    const stripeState = this.consoleState.get('STRIPE');
    //console.log(stripeState)
    if (stripeState === 'pending') {
      const code = queryParams.get('code');
      if (code) {
        this.cleanUpParams();
        this.logger.info('Got code from Stripe!');
        this.stripe.saveIntegration(this.consoleState.get('STRIPE_member'), code);
        this.consoleState.set('STRIPE', 'complete');
        this.idsService.refreshIntegrations();
      } else {
        const error = queryParams.get('error');
        if (error) {
          this.stripe.failIntegration();
          this.cleanUpParams();
        }
      }
    } else {
      this.consoleState.delete('STRIPE');
      this.consoleState.delete('STRIPE_member');
    }
  }

  private checkDropBoxState(queryParams) {
    const dropboxState = this.consoleState.get('DROPBOX');
    if (dropboxState === 'pending') {
      const code = queryParams.get('code');
      if (code) {
        this.cleanUpParams();
        this.logger.info('Got code from Dropbox!');
        this.dropbox.saveIntegration(this.consoleState.get('DROPBOX_member'), code);
        this.consoleState.set('DROPBOX', 'complete');
        this.idsService.refreshIntegrations();
      } else {
        const error = queryParams.get('error');
        if (error) {
          this.dropbox.failIntegration();
          this.cleanUpParams();
        }
      }
    } else {
      this.consoleState.delete('DROPBOX');
      this.consoleState.delete('DROPBOX_member');
    }
  }

}
