// Jonathon Martin, Elementice, September 2019

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class CardPricingService {

  getCostBySheets(numberOfSheets) {
    if (numberOfSheets <= 5) {
      return 130;
    } else if (numberOfSheets <= 10) {
      return 143;
    } else if (numberOfSheets <= 20) {
      return 156;
    } else if (numberOfSheets <= 30) {
      return 169;
    } else if (numberOfSheets <= 50) {
      return 234;
    } else if (numberOfSheets <= 90) {
      return 312;
    } else if (numberOfSheets <= 100) {
      return 325;
    } else if (numberOfSheets <= 120) {
      return 364;
    } else if (numberOfSheets <= 150) {
      return 409.5;
    } else if (numberOfSheets <= 200) {
      return 494;
    } else if (numberOfSheets <= 250) {
      return 585;
    } else if (numberOfSheets > 250) {
      return numberOfSheets * 1.8 * 1.3;
    } else {
      console.error('CardPricingService Exception: bad value for numberOfSheets in getCostBySheets');
    }
  }
}

