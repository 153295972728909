import { Injectable } from '@angular/core';
import { UserService } from '../services/user/user.service';
import { Observable, throwError, from, Subject } from 'rxjs';

import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { catchError, switchMap } from 'rxjs/operators';
import { CognitoService } from '../services/cognito/cognito.service';
import { Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';



@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {

  private $refreshSubject: Subject<any> = new Subject<any>();

  constructor(
    public userService: UserService,
    private cognitoService: CognitoService,
    private router: Router,
    private logger: NGXLogger) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (request.headers.get("Anonymous")) {
      request.headers.delete('Anonymous')
      return next.handle(request);
    }

    request = this.updateHeaders(request);

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          this.logger.warn('Unauthorized request!');
          return this._ifTokenExpired().pipe(
              switchMap(() => next.handle(this.updateHeaders(request))),
              catchError((retryErr) => {
                this.logger.error(retryErr);
                this.logger.info('Failed to authorize, sending to login...');
                this.router.navigate(['/login']);
                return throwError(error);
              })
            );
        } else {
          this.logger.error('Network Error', error);
          return throwError(error);
        }
      }));
  }

  updateHeaders(request: HttpRequest<any>): HttpRequest<any> {
    if (this.userService.user && this.userService.user.token) {
      // this.logger.info('Adding token in request header...');
      let newRequest;
      if (!request.headers.get('User-Agent')) {
        if (request.headers.get('x-access-token') === null) {
          newRequest = request.clone({
            setHeaders: {
              Authorization: this.userService.user.token,
              // 'x-access-token': this.userService.user.token
            }
          });
        } else {
          newRequest = request.clone({
            setHeaders: {
              'x-access-token': this.userService.user.token
            }
          });
        }
      } else {
        newRequest = request;
      }
      return newRequest;
    } else {
      this.logger.info('No user, not adding token.');
      return request;
    }
  }


  private _ifTokenExpired(): Observable<any> {
    this.$refreshSubject.subscribe({
      complete: () => {
        this.$refreshSubject = new Subject<any>();
      }
    });
    if (this.$refreshSubject.observers.length === 1) {
      // Hit refresh-token API passing the refresh token stored into the request
      // to get new access token and refresh token pair
      from(this.cognitoService.checkifUserLoggedIn()).subscribe(this.$refreshSubject);
    }
    return this.$refreshSubject;
  }

}
