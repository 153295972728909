
<app-modal-header [title]="results.importedNum + ' Attendees Imported'"></app-modal-header>

<ion-content *ngIf=results>

  <ion-grid>
    <ion-row *ngIf="results.duplicatesNum > 0">
      <ion-col size=12>
        <p class="text-center">{{results.duplicatesNum}} skipped: imported previously.</p>
      </ion-col>
    </ion-row>
    <ion-row *ngIf="results.conflicts.length === 0">
      <ion-col class=doneMessage>
        <ion-icon name="checkmark-circle" size=large color=success></ion-icon> <ion-text color=success size=large>All done!</ion-text>
      </ion-col>
    </ion-row>
    <ion-row *ngIf="results.errors.length > 0">
      <ion-col size=12>
        <h2 class="text-center font-light text-custom">Errors</h2>
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">ID</th>
                <th scope="col">First Name</th>
                <th scope="col">Last Name</th>
                <th scope="col">Mobile Number</th>
                <th scope="col">Email</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let error of results.errors">
                <td [ngClass]="{'bg-danger': (error.errorCode === 4)}">{{error.id}}</td>
                <td>{{error.firstName}}</td>
                <td>{{error.lastName}}</td>
                <td [ngClass]="{'text-danger': (error.errorCode === 1 || error.errorCode === 3)}">
                  {{error.mobileNumber}}
                </td>
                <td [ngClass]="{'text-danger': (error.errorCode === 2 || error.errorCode === 3)}">
                  {{error.emailAddress}}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <hr/>
      </ion-col>
    </ion-row>

    <ion-row *ngIf="results.conflicts.length > 0">
      <ion-col size=12>
          <h2 class="text-center font-light text-custom">Conflicts</h2>
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">ID</th>
                  <th scope="col">First Name</th>
                  <th scope="col">Last Name</th>
                  <th scope="col">Mobile Number</th>
                  <th scope="col">Email</th>
                  <th scope="col">
                    <ion-button shape=round size=small (click)="useAllNew(results.conflicts)">Accept All New</ion-button>

                  </th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let conflict of results.conflicts">
                  <tr class="attendees-conflict-incoming">
                    <td>{{conflict.incoming.id}}</td>
                    <td>{{conflict.incoming.firstName}}</td>
                    <td>{{conflict.incoming.lastName}}</td>
                    <td>{{conflict.incoming.mobileNumber}}</td>
                    <td>{{conflict.incoming.emailAddress}}</td>
                    <td class="text-center">
                      <ion-button shape=round size=small (click)="useNew(conflict)">Use New</ion-button>
                    </td>
                  </tr>
                  <tr *ngFor="let existingConflict of conflict.existing">
                    <td [ngClass]="{'text-danger': (conflict.incoming.id === existingConflict.id)}">
                      {{existingConflict.id}}
                    </td>
                    <td [ngClass]="{'text-danger': (conflict.incoming.firstName === existingConflict.firstName)}">
                      {{existingConflict.firstName}}</td>
                    <td [ngClass]="{'text-danger': (conflict.incoming.lastName === existingConflict.lastName)}">
                      {{existingConflict.lastName}}</td>
                    <td
                      [ngClass]="{'text-danger': (conflict.incoming.mobileNumber === existingConflict.mobileNumber)}">
                      {{existingConflict.mobileNumber}}</td>
                    <td
                      [ngClass]="{'text-danger': (conflict.incoming.emailAddress === existingConflict.emailAddress)}">
                      {{existingConflict.emailAddress}}</td>
                    <td class="text-center">
                      <ion-button shape=round size=small color=white (click)="useExisting(conflict, existingConflict)">Use Existing</ion-button>
                      </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
      </ion-col>
    </ion-row>
  </ion-grid>

</ion-content>


<app-modal-footer (left)=close() [showRight]=false></app-modal-footer>
