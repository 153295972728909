import { Injectable, OnDestroy } from "@angular/core";
import { BehaviorSubject, Observable, Subject, Subscription } from "rxjs";
import { map, take, shareReplay } from "rxjs/operators";
import { MessageService } from "../services/message/message.service";
import { Router } from "@angular/router";
import { Gallery } from "../models/gallery";
import { EIMedia } from "../models/media";
import { FilterType, GalleryFilter } from "../models/gallery-filter";
import { isSameHour } from "date-fns";
import { HttpClient } from "@angular/common/http";
import { EnvService } from "../services/env/env.service";

@Injectable()
export abstract class GalleryService implements OnDestroy {
  gallery$ = new BehaviorSubject<Gallery>({ images: [], total: 0 });
  currentImage$ = new BehaviorSubject<EIMedia>(null);
  filters$ = new BehaviorSubject<GalleryFilter[]>([]);
  devices$ = new BehaviorSubject<string[]>([]);

  unrolled$: Observable<EIMedia[]> = this.gallery$.pipe(
    map(({ images, total }) => {
      const medias: EIMedia[] = [];
      images.forEach((media, index) => {
        media.timestamp = new Date(media.timestamp);
        if (index === 0) {
          media.firstForEvent = true;
          media.showTime = true;
        } else {
          media.showTime = !isSameHour(
            media.timestamp,
            images[index - 1].timestamp
          );
          media.firstForEvent = media.event !== images[index - 1].event;
        }
        medias.push(media);
      });
      return medias;
    }),
    shareReplay(1)
  );

  loading$ = new BehaviorSubject<boolean>(true);
  loadMore$ = new Subject<boolean>();
  reachedEnd$ = new Subject<{ total: number; loaded: number }>();
  failed$ = new BehaviorSubject<boolean>(false);

  viewGrid = false;
  page = 0;
  limit = 40;
  public sort = "timestamp";
  public sortDirection = "desc";
  public search;
  public publicCode;
  public eventId; // filled only when it's an event gallery

  public id;
  public url;
  public devicesUrl;

  private filters: GalleryFilter[] = [];

  private subs: Array<Subscription> = [];

  constructor(
    router: Router,
    private http: HttpClient,
    private envService: EnvService,
    private messages: MessageService
  ) {
    this.subs.push(
      this.messages.subscribe("newImage", (image) => {
        this.addImage(image);
      }),
      this.messages.subscribe("deleteImages", (deletedImages: EIMedia[]) => {
        this.removeImages(deletedImages);
      }),
      this.messages.subscribe("reassign", (updates) => {
        this.updateImages(updates);
      }),
      this.loadMore$.subscribe((load) => {
        if (load) {
          this.loadMore();
        }
      }),
      this.currentImage$.subscribe((image) => {
        if (image && router.url.includes("galleries")) {
          router.navigate([], {
            queryParams: {
              image: image.publicCode,
            },
            replaceUrl: true,
          });
        }
      })
    );
  }

  ngOnDestroy(): void {
    for (const sub of this.subs) {
      sub.unsubscribe();
    }
  }

  loadMore() {
    this.page = this.page + 1;
    this.http
      .get<Gallery>(
        this.envService.env.api + this.url + this.id + this.buildQueryParams()
      )
      .subscribe((gallery) => {
        const current: any = this.gallery$.getValue();
        current.images = current.images.concat(gallery.images);
        this.gallery$.next(current);
        this.loadMore$.next(false);
        this.reachedEnd$.next({
          total: gallery.total,
          loaded: current.images.length,
        });
      });
  }

  load(id: string, image?: string) {
    this.loading$.next(true);
    this.id = id;
    if (this.devicesUrl) {
      this.http
        .get<string[]>(this.envService.env.api + this.devicesUrl + this.id)
        .pipe(take(1))
        .subscribe((devices) => {
          this.devices$.next(devices);
        });
    }
    this.http
      .get<Gallery>(this.envService.env.api + this.url + id)
      .pipe(take(1))
      .subscribe(
        (gallery) => {
          this.gallery$.next(gallery);
          this.select(this.getFirstPic(gallery, image));
          this.loading$.next(false);
        },
        (_) => {
          this.failed$.next(true);
        }
      );
  }

  reload() {
    const current = this.currentImage$.getValue();
    this.http
      .get<Gallery>(
        this.envService.env.api + this.url + this.id + this.buildQueryParams()
      )
      .pipe(take(1))
      .subscribe(
        (gallery) => {
          this.gallery$.next(gallery);
          this.select(this.getFirstPic(gallery, current?.publicCode));
          this.loading$.next(false);
          this.reachedEnd$.next({
            total: gallery.total,
            loaded: gallery.images.length,
          });
        },
        (_) => {
          this.failed$.next(true);
        }
      );
  }

  updateCurrent(update: Partial<EIMedia>) {
    let current = this.currentImage$.getValue();
    current = { ...current, ...update };
    this.currentImage$.next(current);
  }

  select(image) {
    const current = this.currentImage$.getValue();
    if (!image || !current || current.publicCode !== image.publicCode) {
      this.currentImage$.next(image);
      this.http
        .get<EIMedia>(this.envService.env.api + 'images/get/' + image._id)
        .subscribe(
          (updatedImage) => {
            this.currentImage$.next(updatedImage);
          },
        );
    }
  }

  setPage(page) {
    this.page = page;
    this.reload();
  }

  setSort(field, dir) {
    this.page = 0;
    this.sort = field ? field : this.sort;
    this.sortDirection = dir;
    this.reload();
  }

  onSearch(value) {
    this.page = 0;
    this.publicCode = null;
    this.search = value;
    this.reload();
  }

  onPublicCode(value) {
    this.page = 0;
    this.search = null;
    this.publicCode = value;
    this.reload();
  }

  getFirstPic(gallery: Gallery, publicCode?: string) {
    let rtnPic;
    if (publicCode) {
      rtnPic = gallery.images.find((pic) => pic.publicCode === publicCode);
      if (!rtnPic) {
        rtnPic = gallery.images[0];
      }
      return rtnPic;
    } else {
      return gallery.images[0];
    }
  }

  next() {
    const gallery = this.gallery$.getValue();
    const image = this.currentImage$.getValue();
    const imageIndex = gallery.images.findIndex(
      (pic) => pic.publicCode === image.publicCode
    );
    let newImage = image;
    if (imageIndex < gallery.images.length - 1) {
      newImage = gallery.images[imageIndex + 1];
      this.currentImage$.next(newImage);
    }
    return newImage;
  }

  prev() {
    const gallery = this.gallery$.getValue();
    const image = this.currentImage$.getValue();
    const imageIndex = gallery.images.findIndex(
      (pic) => pic.publicCode === image.publicCode
    );
    let newImage = image;
    if (imageIndex > 0) {
      newImage = gallery.images[imageIndex - 1];
      this.currentImage$.next(newImage);
    }
    return newImage;
  }

  up(columns) {
    const gallery = this.gallery$.getValue();
    const image = this.currentImage$.getValue();
    const imageIndex = gallery.images.findIndex(
      (pic) => pic.publicCode === image.publicCode
    );
    let newImage = image;
    if (imageIndex > columns - 1) {
      newImage = gallery.images[imageIndex - columns];
      this.currentImage$.next(newImage);
    }
    return newImage;
  }

  down(columns) {
    const gallery = this.gallery$.getValue();
    const image = this.currentImage$.getValue();
    const imageIndex = gallery.images.findIndex(
      (pic) => pic.publicCode === image.publicCode
    );
    let newImage = image;
    if (imageIndex < gallery.images.length - columns) {
      newImage = gallery.images[imageIndex + columns];
      this.currentImage$.next(newImage);
    }
    return newImage;
  }

  buildQueryParams(): string {
    const params = [];
    params.push("page=" + this.page);
    if (this.limit) {
      params.push("limit=" + this.limit);
    }
    if (this.sort) {
      params.push("sort=" + this.sort);
    }
    if (this.sortDirection) {
      params.push("sortDirection=" + (this.sortDirection === "desc" ? -1 : 1));
    }
    if (
      this.search &&
      this.filters.findIndex((f) => f.type === FilterType.NO_IDS) < 0
    ) {
      params.push("search=" + this.search);
    }
    if (this.publicCode) {
      params.push("publicCode=" + this.publicCode);
    }
    if (this.filters.length > 0) {
      this.filters.forEach((f) => {
        params.push(f.type.valueOf() + "=" + f.value);
      });
    }
    return "?" + params.join(";");
  }

  toggleFilter(f: GalleryFilter) {
    const matchedFilter = this.filters.findIndex(
      (gf) => gf.type === f.type && (gf.value === f.value || f.value === false)
    );
    if (matchedFilter > -1) {
      this.filters.splice(matchedFilter, 1);
    } else {
      this.filters.push(f);
    }
    this.filters$.next(this.filters);
    this.page = 0;
    this.reload();
  }

  public addImage(imageToAdd) {
    if (this.filters.length === 0 && !this.search) {
      const gallery = this.gallery$.getValue();
      const image = this.currentImage$.getValue();
      imageToAdd.loresUrl = imageToAdd.loresUrl + "?t=" + Date.now();
      imageToAdd.thumbUrl = imageToAdd.thumbUrl + "?t=" + Date.now();
      if (image && image.publicCode === imageToAdd.publicCode) {
        this.currentImage$.next(imageToAdd);
      }
      for (let i = 0; i < gallery.images.length; i++) {
        if (gallery.images[i].publicCode === imageToAdd.publicCode) {
          gallery.images[i] = imageToAdd;
          this.gallery$.next(gallery);
          return;
        }
      }
      gallery.images.unshift({
        ...imageToAdd,
        animateIn: true,
      });

      this.gallery$.next(gallery);
    }
  }

  private removeImages(images: EIMedia[]) {
    const publicCodes = images.map((i) => i.publicCode);
    const currentGallery = this.gallery$.getValue();
    const image = this.currentImage$.getValue();
    if (publicCodes.includes(image.publicCode)) {
      const newImage = this.next();
      if (publicCodes.includes(newImage.publicCode)) {
        this.prev();
      }
    }
    currentGallery.images = currentGallery.images.filter(
      (pic) => !publicCodes.includes(pic.publicCode)
    );
    this.gallery$.next(currentGallery);
  }

  private updateImages(updates: Partial<EIMedia>[]) {
    const currentGallery = this.gallery$.getValue();
    const image = this.currentImage$.getValue();
    currentGallery.images.forEach((im, i) => {
      const update = updates.find((u) => u.publicCode === im.publicCode);
      if (update) {
        if (update.event !== image.event) {
          currentGallery.images.splice(i, 1);
        } else {
          Object.assign(im, update);
        }
      }
    });
    const currentUpdate = updates.find(
      (u) => u.publicCode === image.publicCode
    );
    if (currentUpdate) {
      Object.assign(image, currentUpdate[0]);
    }
    this.gallery$.next(currentGallery);
  }

  abstract open(initial?: any);
}
