import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-modal-footer',
  templateUrl: './modal-footer.component.html',
  styleUrls: ['./modal-footer.component.scss']
})
export class ModalFooterComponent implements OnInit {

  @Input() leftLabel = 'Close';
  @Input() rightLabel = 'Save';
  @Input() actionLabel = '';

  @Input() leftColor = 'danger';
  @Input() rightColor = 'success';
  @Input() actionColor = 'primary';

  @Input() leftIcon = 'close-outline';
  @Input() rightIcon = 'checkmark-outline';

  @Input() leftDisabled = false;
  @Input() rightDisabled = false;
  @Input() actionDisabled = false;

  @Input() showAction = false;
  @Input() showRight = true;
  @Input() rightLoading = false;

  @Input() info = '';
  @Input() infoHeader = '';

  @Output() left = new EventEmitter();
  @Output() right = new EventEmitter();
  @Output() action = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

}
